/* eslint-disable no-unused-expressions */
/* eslint-disable no-const-assign */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import { Item } from '@react-stately/collections'
import { s, styled } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { LOAN_APPLICATION, USER_ROLES } from '@vega/constants'
import { Menu, toast, Loading } from '@vega/components'

import {
  selectApplicationById,
  updateLoanApplication,
  updateLoanApplicationStatus,
  selectIsFetchApplicationPending,
} from 'modules/versionedApplication'
// import { fetchLoanFinancials, selectAllVersionedFinancials } from 'modules/financial'

import { fetchLoanSplits, selectAllLoanSplits } from 'modules/loanSplit'
import { fetchUsers, selectAllUsers } from 'modules/user'
import { formatDateString, formatCurrency, formatFullName } from 'utils/formatters'
import {
  calculateAverageLVR,
  getLoanStructureInformation,
  getBorrowerNamesString,
  mapUsersToDropdownFormat,
} from './headerFormatters'
import { DataDisplay as DataDisplayBase } from './info/components'
import { VersionedDataDisplay as VersionedDataDisplayBase } from '../common'
import { Eligibility } from './headerComponents/eligibilityCriteria/Eligibility'
import { EligibilityVerifications } from './headerComponents/eligibilityVerifications/EligibilityVerifications'
import {
  selectAllEligibilityVerifications,
  listEligibilityVerifications,
} from 'modules/treasury'

const { APPLICATION_STATUS, APPLICATION_STATUS_DISPLAY_TEXT } = LOAN_APPLICATION
const {
  BANKING_PORTFOLIO_ADMIN,
  CREDIT_ASSESSOR,
  LEAD_ASSESSOR,
  SUPER_ADMIN,
} = USER_ROLES

const AssignmentText = styled.p(
  s('font-medium mx-4 my-0 text-base', { letterSpacing: '0.01em', lineHeight: 1.5 })
)
const AssignmentRow = styled.div(s('flex items-center justify-between mb-9'))

const DataDisplay = styled((props) => (
  <DataDisplayBase
    labelStyles={s('font-medium')}
    valueStyles={s('text-grey-900 text-lg')}
    {...props}
  />
))()

const VersionedDataDisplay = styled((props) => (
  <VersionedDataDisplayBase
    labelStyles={s('font-medium')}
    valueStyles={s('text-grey-900 text-lg')}
    {...props}
  />
))()

const Label = styled.p(
  s('font-normal m-0 text-grey-700 text-sm', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const OwnerText = styled.h3(s('my-0 text-2xl text-primary'), {
  letterSpacing: '-0.01em',
  lineHeight: 1.2,
})

const Root = styled.div(
  s('pb-6', {
    backgroundColor: '#E9E9DB',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    paddingLeft: 148,
    paddingRight: 148,
    paddingTop: 120,
  })
)

const applicationStatusErrorHandler = (
  errorDescription,
  statusCode,
  errorMessagePrefix
) => {
  switch (errorDescription) {
    case 'Invalid settlement date':
      toast(`Invalid pre-settlement date`)
      break

    case `Status is already ${APPLICATION_STATUS.SettlementCompleted}`:
      toast(
        `Current status is already ${
          APPLICATION_STATUS_DISPLAY_TEXT[APPLICATION_STATUS.SettlementCompleted]
        }`
      )
      break

    case `Status is already ${APPLICATION_STATUS.SettlementBooked}`:
      toast(
        `Current status is already ${
          APPLICATION_STATUS_DISPLAY_TEXT[APPLICATION_STATUS.SettlementBooked]
        }`
      )
      break

    case `Previous status is not ${APPLICATION_STATUS.SettlementBooked}`:
      toast(
        `Please update status to ${
          APPLICATION_STATUS_DISPLAY_TEXT[APPLICATION_STATUS.SettlementBooked]
        } before updating to ${
          APPLICATION_STATUS_DISPLAY_TEXT[APPLICATION_STATUS.SettlementCompleted]
        }`
      )
      break

    default:
      if (statusCode === 400) toast(`${errorDescription}`)
      else toast(`${errorMessagePrefix}Unexpected error`)
      break
  }
}

const filteredRoles = [
  BANKING_PORTFOLIO_ADMIN,
  CREDIT_ASSESSOR,
  LEAD_ASSESSOR,
  SUPER_ADMIN,
]

const formatSettlementDate = (date) => (isNotNilOrEmpty(date) ? new Date(date) : '-')

export const LoanAssignmentHeader = ({ ...props }) => {
  const { id: versionedLoanApplicationId } = useParams()
  const dispatch = useDispatch()

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const allocatedLoanStructures = useSelector(selectAllLoanSplits)

  const versionedApplication = useSelector(
    selectApplicationById(versionedLoanApplicationId)
  )
  const loanSplits = useSelector(selectAllLoanSplits) ?? []
  const users = useSelector(selectAllUsers) ?? []
  const eligibilityVerifications = useSelector(selectAllEligibilityVerifications)
  const [applicationStatus, setApplicationStatus] = useState()
  const [assignedUser, setAssignedUser] = useState('')
  const showFullHistory = true

  const { latestVersionId } = versionedApplication

  const latestApplicationVersion = !versionedApplication?.versions
    ? {}
    : versionedApplication.versions.find(
        (version) => version.applicationId === latestVersionId
      ).application || {}

  const assignedToId = latestApplicationVersion?.assignedTo?.id

  const currentEligibilityVerifications = !versionedApplication?.versions
    ? []
    : versionedApplication.versions.find(
        (version) => version.applicationId === latestVersionId
      ).currentEligibilityVerifications || []

  // const versionedFinancials = useSelector(selectAllVersionedFinancials) ?? []
  // const loanFinancials = versionedFinancials[versionedFinancials?.length - 1] || []

  useEffect(() => {
    dispatch(fetchUsers({ searchParams: { filters: { role: filteredRoles } } }))
    dispatch(fetchLoanSplits(versionedLoanApplicationId))
    // dispatch(fetchLoanFinancials({ id: versionedLoanApplicationId, showFullHistory }))
    dispatch(listEligibilityVerifications({}))
  }, [dispatch, versionedLoanApplicationId, showFullHistory])

  useEffect(() => {
    setAssignedUser(assignedToId)
  }, [assignedToId])

  useEffect(() => {
    setApplicationStatus(latestApplicationVersion?.status?.currentStatus)
  }, [latestApplicationVersion.status])

  if (isFetchingApplication) {
    return (
      <Root {...props}>
        <div style={s('py-16')}>
          <Loading />
        </div>
      </Root>
    )
  }

  const legalEntities = [
    ...(latestApplicationVersion?.legalEntities?.individuals || []),
    ...(latestApplicationVersion?.legalEntities?.trusts || []),
    ...(latestApplicationVersion?.legalEntities?.companies || []),
  ]

  const neoApplicationNumber = latestApplicationVersion?.neoApplicationNumber

  // The following code creates data history arrays for detecting re-submission changes and showing a prior version in a tool-tip

  const versionedHeaderInfo = {
    versionedSettlementDate: [],
    versionedLoanStructure: [],
    versionedTotalLoanAmounts: [],
    versionedTotalLoanDuration: [],
    versionedLvr: [],
  }
  versionedApplication?.versions?.map((version) => {
    const settlementdate = formatSettlementDate(
      version.application.expectedSettlementDate
    )
    const {
      loanStructure,
      totalLoanAmount,
      totalLoanDuration,
    } = getLoanStructureInformation(loanSplits, version.application.requestedLoans)
    const lvr = calculateAverageLVR(
      version.application.customSecurityValuations,
      version.application.totalLoanAmount,
      version.application.securities
    )
    versionedHeaderInfo.versionedSettlementDate = [
      ...versionedHeaderInfo.versionedSettlementDate,
      formatDateString(settlementdate),
    ]
    versionedHeaderInfo.versionedLoanStructure = [
      ...versionedHeaderInfo.versionedLoanStructure,
      loanStructure,
    ]
    versionedHeaderInfo.versionedTotalLoanAmounts = [
      ...versionedHeaderInfo.versionedTotalLoanAmounts,
      formatCurrency(totalLoanAmount),
    ]
    versionedHeaderInfo.versionedTotalLoanDuration = [
      ...versionedHeaderInfo.versionedTotalLoanDuration,
      `${totalLoanDuration} years`,
    ]
    versionedHeaderInfo.versionedLvr = [...versionedHeaderInfo.versionedLvr, lvr]
    return true
  })

  const {
    versionedSettlementDate = [],
    versionedLoanStructure = [],
    versionedTotalLoanAmounts = [],
    versionedTotalLoanDuration = [],
    versionedLvr = [],
  } = versionedHeaderInfo

  const getCrr = () => {
    if (
      isNotNilOrEmpty(latestApplicationVersion?.creditRiskRating?.letter) &&
      isNotNilOrEmpty(latestApplicationVersion?.creditRiskRating?.number)
    ) {
      return `${latestApplicationVersion?.creditRiskRating?.letter}${latestApplicationVersion?.creditRiskRating?.number}`
    }
    return '-'
  }

  const USERS = mapUsersToDropdownFormat(users)

  const displayUserFullName = (newValue) => {
    if (isNilOrEmpty(newValue)) return null

    const { firstName, lastName } = users.find(({ id }) => id === newValue) || {}

    return formatFullName({ firstName, lastName })
  }

  const displayApplicationStatus = (newValue) => {
    if (isNilOrEmpty(newValue)) return null

    return APPLICATION_STATUS_DISPLAY_TEXT[newValue]
  }

  const getLoanStructure = (loanStructure, allocatedLoans) => {
    if (isNilOrEmpty(allocatedLoans)) return loanStructure

    if (allocatedLoans.length > 1) {
      return 'Split'
    }
    return 'Single'
  }

  const getVersionedLoanStructures = versionedLoanStructure?.map((loanStructure) => {
    const result = getLoanStructure(loanStructure, allocatedLoanStructures)
    return result
  })

  const updateAssignedTo = async (newUser) => {
    const { id, firstName, lastName, email } = users.find(({ id }) => id === newUser)
    const payload = { assignedTo: { id, firstName, lastName, email } }

    const { error } = dispatch(
      updateLoanApplication({
        id: versionedLoanApplicationId,
        payload,
      })
    )
    if (error) {
      toast('Application assignment failed')
      return
    }

    toast('Application assignment successful')
    setAssignedUser(newUser)
  }

  return (
    <Root {...props}>
      <AssignmentRow style={{ marginBottom: 10 }}>
        <OwnerText>
          {getBorrowerNamesString(legalEntities)}
          &apos;s Application
        </OwnerText>

        <AssignmentText>currently</AssignmentText>

        <Menu
          aria-label="Application Status"
          label="Application Status"
          onAction={async (status) => {
            try {
              const resultAction = await dispatch(
                updateLoanApplicationStatus({
                  loanApplicationId: versionedLoanApplicationId,
                  status,
                })
              )
              unwrapResult(resultAction)

              toast('Application status updated successfully')
              setApplicationStatus(status)
            } catch (error) {
              const errorMessagePrefix = 'Update application status failed: '
              applicationStatusErrorHandler(
                error?.description,
                error?.meta?.statusCode,
                errorMessagePrefix
              )
            }
          }}
          displayTextSelector={displayApplicationStatus}
          placeholder="Application Status"
          selectedValue={applicationStatus}
          style={s('w-16')}
        >
          {Object.entries(APPLICATION_STATUS_DISPLAY_TEXT).map(([key, value]) => (
            <Item key={key}>{value}</Item>
          ))}
        </Menu>

        <AssignmentText>assigned to</AssignmentText>

        <Menu
          aria-label="Assigned to"
          displayTextSelector={displayUserFullName}
          label="Whom?"
          onAction={updateAssignedTo}
          placeholder="Whom?"
          selectedValue={assignedUser}
          style={s('w-16')}
        >
          {Object.entries(USERS).map(([key, value]) => (
            <Item key={key}>{value}</Item>
          ))}
        </Menu>
      </AssignmentRow>

      <AssignmentRow>
        <AssignmentText style={{ marginLeft: 0 }}>
          {`Neo Application Number: ${neoApplicationNumber || '-'}`}
        </AssignmentText>
      </AssignmentRow>

      <Row justify="space-between">
        <Col span={3}>
          <VersionedDataDisplay
            label="Loan Structure"
            values={getVersionedLoanStructures}
          />
        </Col>

        <Col span={3}>
          <VersionedDataDisplay
            label="Loan Amount"
            values={versionedTotalLoanAmounts}
          />
        </Col>

        <Col span={3}>
          <VersionedDataDisplay
            label="Loan Duration"
            values={versionedTotalLoanDuration}
          />
        </Col>

        <Col span={4}>
          <VersionedDataDisplay
            label="Expected Settlement Date"
            values={versionedSettlementDate}
          />
        </Col>

        <Col span={2}>
          <VersionedDataDisplay label="%LVR" values={versionedLvr} />
        </Col>

        <Col span={2}>
          <DataDisplay label="CRR" value={getCrr()} />
        </Col>
        <Col span={3}>
          <Label style={s('font-medium')}>Eligibility</Label>
          <Eligibility
            versionedLoanApplicationId={versionedLoanApplicationId}
            latestApplicationVersion={latestApplicationVersion}
            lvr={versionedLvr[versionedLvr.length - 1]}
            currentEligibilityVerifications={currentEligibilityVerifications}
            // loanFinancials={loanFinancials}
          />
        </Col>
        <Col span={3}>
          <Label style={s('font-medium')}>CheckList</Label>
          <EligibilityVerifications
            versionedLoanApplicationId={versionedLoanApplicationId}
            eligibilityVerifications={eligibilityVerifications}
            currentEligibilityVerifications={currentEligibilityVerifications}
          />
        </Col>
      </Row>
    </Root>
  )
}
