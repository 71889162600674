import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { isNotNilOrEmpty } from '@neo/ramda-extra'

const Root = styled.div(
  s('flex justify-between p-5 w-full space-y-4', {
    flexDirection: 'column',
    gap: '10px',
  })
)

const Text = styled.p(
  s('font-normal m-0 text-base text-grey-900', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const Circles = styled.div(
  s('flex items-center justify-center mr-1 rounded-3xl text-grey-700', {
    height: 20,
    width: 20,
  }),
  ({ status }) => {
    if (status === 'pass') {
      return s('bg-secondary')
    }
    if (status === 'fail') {
      return s('bg-red')
    }
    return s('border-solid border-secondary')
  }
)
export const Eligibilities = ({ eligibilities }) => {
  return (
    <Root>
      {isNotNilOrEmpty(eligibilities) ? (
        eligibilities.map((eligibility, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <Text>{eligibility?.name || 'N/A'}</Text>
            <Circles style={s('text-grey-600 text-sm')} status={eligibility.status} />
          </div>
        ))
      ) : (
        <Text>No eligibility information available.</Text>
      )}
    </Root>
  )
}
