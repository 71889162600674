import {
  monthlyNetIncome,
  sensitisedMonthlyNetIncome,
  householdEssentialBenchmarkAmount,
  monthlyGrossIncome,
} from './incomes/utils'
import { totalMonthlyExpenses } from './expenses/utils'
import {
  totalCostOfServicing,
  totalEffectiveCostOfServicing,
  revolvingCreditLiabilities,
  studentLoanLeaseHirePurchaseLiabilities,
  longTermLoanMortgageLiabilities,
} from './liabilities/utils'

// eslint-disable-next-line complexity
const customerUMIWithoutHaircuts = ({ incomes, expenses, liabilities }) => {
  const netIncomeAfterTaxAndKiwiSaver = monthlyNetIncome(incomes) || 0
  const totalDeclaredHouseholdExpenses = totalMonthlyExpenses(expenses) || 0

  const revolvingCreditCostOfServicing =
    totalCostOfServicing(revolvingCreditLiabilities(liabilities)) || 0

  const studentLoanLeaseHirePurchaseCostOfServicing =
    totalCostOfServicing(studentLoanLeaseHirePurchaseLiabilities(liabilities)) || 0

  const longTermLoanMortgageCostOfServicing =
    totalCostOfServicing(longTermLoanMortgageLiabilities(liabilities)) || 0

  return (
    netIncomeAfterTaxAndKiwiSaver -
    totalDeclaredHouseholdExpenses -
    revolvingCreditCostOfServicing -
    studentLoanLeaseHirePurchaseCostOfServicing -
    longTermLoanMortgageCostOfServicing
  )
}

const customerUMIWithHaircuts = ({ incomes, expenses, liabilities }) => {
  const sensitisedAfterTaxAndKiwiSaver = sensitisedMonthlyNetIncome(incomes)
  const totalDeclaredHouseholdExpenses = totalMonthlyExpenses(expenses)

  const revolvingCreditCostOfServicing = totalEffectiveCostOfServicing(
    revolvingCreditLiabilities(liabilities)
  )

  const studentLoanLeaseHirePurchaseCostOfServicing = totalEffectiveCostOfServicing(
    studentLoanLeaseHirePurchaseLiabilities(liabilities)
  )

  const longTermLoanMortgageCostOfServicing = totalEffectiveCostOfServicing(
    longTermLoanMortgageLiabilities(liabilities)
  )

  return (
    sensitisedAfterTaxAndKiwiSaver -
    totalDeclaredHouseholdExpenses -
    revolvingCreditCostOfServicing -
    studentLoanLeaseHirePurchaseCostOfServicing -
    longTermLoanMortgageCostOfServicing
  )
}

const benchmarkUMI = ({ incomes, expenses, liabilities, structures }) => {
  const sensitisedAfterTaxAndKiwiSaver = sensitisedMonthlyNetIncome(incomes)

  const totalDeclaredHouseholdExpenses = Math.max(
    totalMonthlyExpenses(expenses),
    householdEssentialBenchmarkAmount(structures, monthlyGrossIncome(incomes))
  )

  const revolvingCreditCostOfServicing = totalEffectiveCostOfServicing(
    revolvingCreditLiabilities(liabilities)
  )

  const studentLoanLeaseHirePurchaseCostOfServicing = totalEffectiveCostOfServicing(
    studentLoanLeaseHirePurchaseLiabilities(liabilities)
  )

  const longTermLoanMortgageCostOfServicing = totalEffectiveCostOfServicing(
    longTermLoanMortgageLiabilities(liabilities)
  )

  return (
    sensitisedAfterTaxAndKiwiSaver -
    totalDeclaredHouseholdExpenses -
    revolvingCreditCostOfServicing -
    studentLoanLeaseHirePurchaseCostOfServicing -
    longTermLoanMortgageCostOfServicing
  )
}

const umiSummaryFields = (incomes, expenses, liabilities, structures) => {
  const withoutHaircuts = customerUMIWithoutHaircuts({
    incomes,
    expenses,
    liabilities,
  })

  const withHaircuts = customerUMIWithHaircuts({
    incomes,
    expenses,
    liabilities,
  })

  const benchmark = benchmarkUMI({
    incomes,
    expenses,
    liabilities,
    structures,
  })

  return { withoutHaircuts, withHaircuts, benchmark }
}

export const dashboardResults = ({ incomes, expenses, liabilities, structures }) => {
  // TODO: refilter these, or pass them in filtered
  const householdIncomes = incomes
  const householdExpenses = expenses
  const householdLiabilities = liabilities

  const guarantorIncomes = []
  const guarantorExpenses = []
  const guarantorLiabilities = []

  // const householdIncomes = incomes.filter((income) => income.applicantRole === 'borrower')
  // const householdExpenses = expenses.filter((expense) => expense.applicantRole === 'borrower')
  // const householdLiabilities = liabilities.filter((liability) => liability.applicantRole === 'borrower')

  // const guarantorIncomes = incomes.filter((income) => income.applicantRole === 'guarantor')
  // const guarantorExpenses = expenses.filter((expense) => expense.applicantRole === 'guarantor')
  // const guarantorLiabilities = liabilities.filter((liability) => liability.applicantRole === 'guarantor')

  const applicantFields = umiSummaryFields(
    householdIncomes,
    householdExpenses,
    householdLiabilities,
    structures
  )

  const guarantorFields = umiSummaryFields(
    guarantorIncomes,
    guarantorExpenses,
    guarantorLiabilities
  )

  const overallCustomerUMIWithoutHaircuts =
    applicantFields.withoutHaircuts + guarantorFields.withoutHaircuts

  const overallBenchmarkUMI = applicantFields.benchmark + guarantorFields.benchmark

  const finalUMI = Math.min(overallCustomerUMIWithoutHaircuts, overallBenchmarkUMI)

  return {
    finalUMI,
    customerUMIWithoutHaircuts: {
      applicantsStandalone: applicantFields.withoutHaircuts,
      withGaurantors: applicantFields.withoutHaircuts + guarantorFields.withoutHaircuts,
    },
    customerUMIWithHaircuts: {
      applicantsStandalone: applicantFields.withHaircuts,
      withGaurantors: applicantFields.withHaircuts + guarantorFields.withHaircuts,
    },
    benchmarkUMI: {
      applicantsStandalone: applicantFields.benchmark,
      withGaurantors: applicantFields.benchmark + guarantorFields.benchmark,
    },
  }
}
