import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { ContentCard, SectionTitle } from '../components'
import { ReactComponent as ChevronDown } from '@vega/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@vega/components/src/assets/images/chevron-up.svg'
import { TEST_IDS } from '@vega/constants'
import { VersionedValue } from '../../../common/VersionedValue'
import { VersionedDataDisplay } from '../../../common/VersionedDataDisplay'
import { versionedValues } from './versionedLoanInfoValues'

const { LOAN_INFO } = APPLICATION_INFO_SECTION_IDS

const InfoSegmentTitle = styled.h4(s('text-Base mb-3'))
const InfoSegment = styled.div(s('flex flex-column'), {
  borderBottom: '1px solid #E4E6E6',
})

const InfoRow = styled.div(s('flex flex-row mb-6'))
const SubTitleAndValueContainer = styled.div(s('flex flex-column mr-4', { width: 204 }))
const SubSection = styled.div(s('text-sm font-normal text-primary font-semibold mb-3'))
const SubTitle = styled.div(s('text-grey-700 text-sm font-normal'))

const RequestTitle = styled.div(s('text-primary text-xs font-medium mb-2 mt-4'))

const AnalysisExpansionButton = styled.button(
  s('text-primary bg-white text-base border-0 m-10 font-semibold cursor-pointer'),
  {
    hover: { cursor: 'cursor-pointer' },
  }
)

const ChevronDownIcon = styled(ChevronDown)(s('ml-1'), {
  cursor: 'pointer',
  path: { stroke: s('text-primary').color },
})
const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primary').color },
})

export const InfoOverview = ({ loanInfo = {}, versionedLoanInfo = {} }) => {
  const { requestedLoans } = loanInfo

  const {
    versionedTotalLoanAmount,
    versionedAmountRequested,
    versionedTermTypeCycle,
    versionedTotalTermDuration,
    versionedPurpose,
    versionedIoDuration,
    versionedOwners,
    versionedContributionFundsTotal,
    versionedFunds,
    versionedNeedsAnalysisValues,
  } = versionedValues(loanInfo, versionedLoanInfo)

  const [isExpanded, setIsExpanded] = React.useState(false)
  const toggleExpand = () => {
    const newValue = !isExpanded
    setIsExpanded(newValue)
  }

  return (
    <div key={'loan_info'} id={LOAN_INFO} style={{ marginBottom: 52 }}>
      <SectionTitle title="Loan Info" style={s('mb-4')} />

      <ContentCard>
        <InfoSegment>
          <InfoSegmentTitle style={s('mt-0')}>Loan Requested</InfoSegmentTitle>
          <Row>
            <Col span={24}>
              <SubTitleAndValueContainer>
                <SubTitle>Total Loan Requested</SubTitle>
                <VersionedValue
                  testId={TEST_IDS.TOTAL_LOAN_REQUESTED}
                  values={versionedTotalLoanAmount}
                  valueStyles={s('text-xl font-bold')}
                />
              </SubTitleAndValueContainer>
            </Col>
          </Row>

          {requestedLoans?.map(({ term }, index) => (
            <div key={`${index}-requestedLoan`}>
              <RequestTitle>REQUEST {index + 1}</RequestTitle>

              <Row>
                <Col span={6}>
                  <SubTitleAndValueContainer>
                    <VersionedDataDisplay
                      label="Loan amount"
                      testId={`${TEST_IDS.LOAN_AMOUNT}`}
                      values={versionedAmountRequested[index]}
                    />
                  </SubTitleAndValueContainer>
                </Col>

                <Col span={6}>
                  <SubTitleAndValueContainer>
                    <VersionedDataDisplay
                      label="Term Type Cycle"
                      testId={`${TEST_IDS.LOAN_TERM_CYCLE}`}
                      values={versionedTermTypeCycle[index]}
                    />
                  </SubTitleAndValueContainer>
                </Col>

                <Col span={6}>
                  <SubTitleAndValueContainer>
                    <VersionedDataDisplay
                      label="Total Duration"
                      testId={`${TEST_IDS.LOAN_DURATION}`}
                      values={versionedTotalTermDuration[index]}
                    />
                  </SubTitleAndValueContainer>
                </Col>

                <Col span={6}>
                  <SubTitleAndValueContainer>
                    <VersionedDataDisplay
                      label="Purpose"
                      testId={`${TEST_IDS.LOAN_PURPOSE}`}
                      values={versionedPurpose[index]}
                    />
                  </SubTitleAndValueContainer>
                </Col>
              </Row>

              {term?.paymentType === 'Interest Only' && (
                <Row style={s('mt-4')}>
                  <Col span={6}>
                    <SubTitleAndValueContainer>
                      <VersionedDataDisplay
                        label="IO Duration"
                        values={versionedIoDuration[index]}
                      />
                    </SubTitleAndValueContainer>
                  </Col>
                </Row>
              )}

              <Row key={`${index}-owners`} gutter={[24, 0]} style={s('my-4')}>
                {versionedOwners[index]?.map(({ name, percent }, idx) => (
                  <Col span={6} key={idx}>
                    <SubTitleAndValueContainer>
                      <VersionedValue
                        testId={TEST_IDS.LOAN_OWNERSHIP}
                        values={name}
                        valueStyles={s('font-normal m-0 text-grey-700 text-sm', {
                          letterSpacing: '0.01em',
                          lineHeight: 1.5,
                        })}
                      />
                      <VersionedValue
                        values={percent}
                        valueStyles={s('font-semibold m-0 text-base text-grey-800', {
                          letterSpacing: '0.01em',
                          lineHeight: 1.5,
                        })}
                      />
                    </SubTitleAndValueContainer>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </InfoSegment>

        <InfoSegment>
          <InfoSegmentTitle>Contribution Funds</InfoSegmentTitle>

          <InfoRow>
            <SubTitleAndValueContainer>
              <SubTitle>Total Contribution Funds</SubTitle>
              <VersionedValue
                values={versionedContributionFundsTotal}
                valueStyles={s('font-extrabold m-0 text-base text-grey-800', {})}
              />
            </SubTitleAndValueContainer>
          </InfoRow>

          {versionedFunds?.map(({ domainTypes, amounts, descriptions }) => (
            <>
              <VersionedValue
                values={domainTypes}
                valueStyles={s('text-xs text-primary')}
              />
              <InfoRow>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay label="Amount" values={amounts} />
                </SubTitleAndValueContainer>

                <SubTitleAndValueContainer>
                  <VersionedDataDisplay label="Description" values={descriptions} />
                </SubTitleAndValueContainer>
              </InfoRow>
            </>
          ))}
        </InfoSegment>

        {!isExpanded ? (
          <InfoSegment style={s('border-transparent')}>
            <InfoSegmentTitle>Analysis</InfoSegmentTitle>

            <AnalysisExpansionButton onClick={toggleExpand}>
              <Row style={s('justify-center')}>
                <div>View Analysis</div>
                <ChevronDownIcon width={20} height={20} />
              </Row>
            </AnalysisExpansionButton>
          </InfoSegment>
        ) : (
          <InfoSegment style={s('border-transparent')}>
            <InfoSegmentTitle>Analysis</InfoSegmentTitle>

            <SubSection>Application Preferences</SubSection>

            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Conflict Exists"
                    values={versionedNeedsAnalysisValues?.preferences?.conflictExists}
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Interest Rate Type"
                    values={versionedNeedsAnalysisValues?.preferences?.interestRateType}
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Preferred Lenders"
                    values={versionedNeedsAnalysisValues?.preferences?.preferredLenders}
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Preferred Lenders Details"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.preferredLendersDetails
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <SubSection>Funds Access Type Details</SubSection>

            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Offset Account"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.fundsAccessTypeDetails
                        ?.offsetAccount?.reason?.description
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Importance"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.fundsAccessTypeDetails
                        ?.offsetAccount?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Risks Explained"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.fundsAccessTypeDetails
                        ?.offsetAccount?.risksExplained
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <SubSection>Redraw</SubSection>

            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Importance"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.fundsAccessTypeDetails
                        ?.redraw?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Risks Explained"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.fundsAccessTypeDetails
                        ?.redraw?.risksExplained
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <SubSection style={s('mt-2')}>
              Interest Rate Preferences: Fixed and Variable Rate
            </SubSection>

            <Row style={s('mb-2')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Importance"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.interestRateTypeDetails
                        ?.fixedAndVariableRate?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Risks Explained"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.interestRateTypeDetails
                        ?.fixedAndVariableRate?.risksExplained
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Fixed Period Duration"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.interestRateTypeDetails
                        ?.fixedAndVariableRate?.fixedPeriodDuration?.length
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Reason"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.interestRateTypeDetails
                        ?.fixedAndVariableRate?.reason?.description
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Fixed Rate Importance"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.interestRateTypeDetails
                        ?.fixedRate?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Variable Rate Importance"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.interestRateTypeDetails
                        ?.variableRate?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <SubSection style={s('mt-2')}>
              Repayment Type Details: Interst in Advance
            </SubSection>

            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Importance"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.interestInAdvance?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Interest Only Importanace"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.interestOnly?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Line of Credit"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.lineOfCredit?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Offset Risk Explained"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.interestInAdvance?.offsetRisksExplained
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <SubSection style={s('mt-2')}>Principal and Interest</SubSection>

            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Importance"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Frequency"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.repaymentFrequency
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Risk Explained"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.risksExplained
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Offset Risk Explained"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.offsetRisksExplained
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <SubSection style={s('mt-2')}>
              Purpose of Application: Refinancing & Consolidation
            </SubSection>

            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Increase Interest Risk"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.explainedIncreaseInterestRisk
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Increase Loan Term Risk"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.explainedIncreaseLoanTermRisk
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Plan to Close/Reduce Credit card"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.planToCloseOrReduceCreditCard
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Offset Risk Explained"
                    values={
                      versionedNeedsAnalysisValues?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.offsetRisksExplained
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <SubSection style={s('mt-2')}>Refinancing Reason</SubSection>

            <Row style={s('mb-2')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Close to End of Current Loan Term"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.refinancingReason?.closeToEndOfCurrentLoanTerm
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Debt Consolidation"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.refinancingReason?.closeToEndOfCurrentLoanTerm
                        ?.debtConsolidationDetails
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Dissatifsfaction with Current Lender"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.refinancingReason?.closeToEndOfCurrentLoanTerm
                        ?.dissatisfactionWithCurrentLender
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Greater Flexbilitiy"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.refinancingReason
                        ?.closeToEndOfCurrentLoanTermgreaterFlexibility
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Increase Total Loan Amount"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.refinancingReason?.increaseTotalLoanAmount
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Lower Interest Rate"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.refinancingReason?.lowerInterestRateDetails
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Reduced Repayment"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.refinancingReason?.reducedRepaymentsDetails
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>

              <Col span={6}>
                <SubTitleAndValueContainer>
                  <VersionedDataDisplay
                    label="Other"
                    values={
                      versionedNeedsAnalysisValues?.refinancingAndConsolidation
                        ?.refinancingReason?.otherDetails
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>

            <AnalysisExpansionButton onClick={toggleExpand}>
              <Row style={s('justify-center')}>
                <div>Collapse</div>
                <ChevronUpIcon width={20} height={20} />
              </Row>
            </AnalysisExpansionButton>
          </InfoSegment>
        )}
      </ContentCard>
    </div>
  )
}
