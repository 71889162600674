/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { formatCurrency } from 'utils/formatters'
import { SecurityCard } from './SecurityCard'
import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { Loading, Button } from '@vega/components'
import { isNotNilOrEmpty, isNilOrEmpty } from '@solta/ramda-extra'
import { NoteSection } from '../../common/NoteSection'
import { LinkValocityModal } from './LinkValocityModal'
import { TEST_IDS } from '@vega/constants'
import { SecurityForm } from './SecurityForm'

import {
  DataDisplay,
  SectionTitle,
  SectionTitle as SectionTitleBase,
} from '@vega/components/src/v2'

import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'

import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/versionedApplication'

import { selectAllSecurities, fetchSecurities } from 'modules/security'
import { RemoveSecurityModal } from './removeSecurityModal'

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)

const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

const getCustomEstimateValue = (customSecurityValuations = [], securityId) => {
  const desiredValuation = customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )
  return desiredValuation?.value
}

const mapSecurityProperties = (securities = [], customSecurityValuations = []) => {
  return securities.map((security) => {
    const { id, linkedDetails = {} } = security
    const customEstimateValue = getCustomEstimateValue(customSecurityValuations, id)

    return {
      id,
      value: isNotNilOrEmpty(customEstimateValue)
        ? customEstimateValue
        : linkedDetails?.valuation?.estimatedValue,
    }
  })
}

const getTotalEstimatedSecurityValue = (securities) => {
  return securities
    ?.map((security) =>
      isNilOrEmpty(security?.linkedDetails)
        ? security?.estimatedValue?.value
        : security?.linkedDetails?.valuation?.estimatedValue
    )
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
}

const getTotalFinalAssetValue = (securities) => {
  return securities
    ?.map((security) => security?.value)
    .reduce((previousValue, currentValue) => previousValue + (currentValue || 0), 0)
}

const groupEntitiesByApplicantRole = (individuals = []) => {
  const borrowers = []
  const guarantors = []

  individuals.forEach((individual) => {
    if (individual.applicantRole === 'Borrower') borrowers.push(individual)
    else if (individual.applicantRole === 'Guarantor') guarantors.push(individual)
  })

  return {
    borrowers,
    guarantors,
  }
}

const mapEntities = (borrowers = []) =>
  borrowers.map((borrower) => ({
    id: borrower?.entityId,
    fullName: borrower?.fullName,
    legalEntityType: borrower?.legalEntityType,
  }))

export const Security = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [modalSecurity, setModalSecurity] = useState()
  const [showSecutiryForm, setshowSecutiryForm] = useState(false)

  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false)

  const versionedApplication = useSelector(selectApplicationById(id))

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const losSecurities = useSelector(selectAllSecurities) || []

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchSecurities(id))
  }, [dispatch, id])

  if (isFetchingApplication) return <Loading />

  const { latestVersionId } = versionedApplication
  const latestApplicationVersion =
    versionedApplication.versions.find(
      (version) => version.applicationId === latestVersionId
    ).application || {}

  const {
    // securities = [],
    customSecurityValuations = [],
    legalEntities = [],
  } = latestApplicationVersion

  const { individuals = [], trusts = [], companies = [] } = legalEntities
  const { borrowers, guarantors } = groupEntitiesByApplicantRole([
    ...individuals,
    ...trusts,
    ...companies,
  ])

  const cannotAddSecurity = !!(
    latestApplicationVersion?.status?.currentStatus === 'settlement_completed' ||
    latestApplicationVersion?.status?.currentStatus === 'settlement_date_booked'
  )

  const mappedAllBorrowers = mapEntities(borrowers)
  const mappedAllGuarantors = mapEntities(guarantors)

  const activeLosSecurities = losSecurities.filter(
    (security) => security.status === 'Active'
  )

  const mappedAllSecurityProperties = mapSecurityProperties(
    activeLosSecurities,
    customSecurityValuations
  )

  const openForm = () => {
    setshowSecutiryForm(true)
  }

  const removedSecurities =
    losSecurities?.filter((security) => security?.status === 'Removed') || []

  return (
    <div style={s('ml-8')}>
      <BackButton
        variant="text"
        style={s('mb-8 ml-24')}
        onClick={() => history.push(`/loan-applications/${id}/financial`)}
      >
        <ChevronLeft /> <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>
      <Row style={s('px-24')} gutter={[0, 10]}>
        <Col span={24}>
          <h1 style={s('font-semibold mb-10')} className="sohne">
            Securities Info
          </h1>
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Securities"
            value={activeLosSecurities?.length}
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_SECURITIES}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Properties"
            value={activeLosSecurities?.length}
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_PROPERTIES}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Guarantors"
            value="-"
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_GUARNATORS}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Declared Security Value"
            value={formatCurrency(getTotalEstimatedSecurityValue(activeLosSecurities))}
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_DECLARED}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Assessed Security Value"
            value={formatCurrency(getTotalFinalAssetValue(mappedAllSecurityProperties))}
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_ASSESSED}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Guarantor Value"
            value="-"
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
          />
        </Col>

        <Col span={24}>
          <SectionTitle title="Property" style={s('mt-9 mb-3')} />
          {losSecurities
            ?.filter((security) => security.status === 'Active')
            .map((security, index) => (
              <SecurityCard
                security={security}
                loanApplicationId={id}
                customSecurityValuations={customSecurityValuations}
                legalEntities={legalEntities}
                key={security.id}
                setIsModalOpened={setIsModalOpened}
                setModalSecurity={setModalSecurity}
                setIsRemoveModalOpened={setIsRemoveModalOpened}
                cannotRemoveSecurity={cannotAddSecurity}
                allBorrowers={mappedAllBorrowers}
                allGuarantors={mappedAllGuarantors}
                index={index}
              />
            ))}
        </Col>

        {showSecutiryForm && (
          <SecurityForm
            showSecutiryForm={setshowSecutiryForm}
            versionedId={id}
            allBorrowers={mappedAllBorrowers}
            allGuarantors={mappedAllGuarantors}
          />
        )}
        <Col span={24}>
          <NoteSection
            id="note-section"
            versionedLoanApplicationId={id}
            loanApplicationId={latestVersionId}
            source="loan-applications/security"
          />
        </Col>
        <div>
          <p style={s('mb-3 ')}>
            <strong>Need to add another security?</strong>
          </p>
          <Button onClick={openForm} style={s('mb-3')} disabled={cannotAddSecurity}>
            Add security
          </Button>
        </div>
      </Row>

      {isNotNilOrEmpty(removedSecurities) && (
        <Row style={s('px-24')} gutter={[0, 10]}>
          <SectionTitle title="Removed Properties" style={s('mt-9 mb-3')} />
          <Col span={24}>
            {removedSecurities.map((security, index) => (
              <SecurityCard
                security={security}
                loanApplicationId={id}
                customSecurityValuations={customSecurityValuations}
                legalEntities={legalEntities}
                key={security.id}
                setIsModalOpened={setIsModalOpened}
                setModalSecurity={setModalSecurity}
                setIsRemoveModalOpened={setIsRemoveModalOpened}
                index={index}
              />
            ))}
          </Col>
        </Row>
      )}

      <LinkValocityModal
        isOpen={isModalOpened}
        setIsOpen={setIsModalOpened}
        security={modalSecurity}
      />

      <RemoveSecurityModal
        isOpen={isRemoveModalOpened}
        setIsOpen={setIsRemoveModalOpened}
        security={modalSecurity}
      />
    </div>
  )
}
