import BaseService from '../baseService'

export class TreasuryService extends BaseService {
  listWarehouseEligibilities(eligibilityPayload = []) {
    const config = {
      json: eligibilityPayload,
    }
    return this.client.post('loan-applications/validate', config).json()
  }

  listEligibilityVerifications() {
    return this.client.get('eligibility-verifications').json()
  }
}
