/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircleArrayComponent from './CirclesComponent'
import {
  selectAllWarehousesEligibilities,
  listWarehouseEligibilities,
} from 'modules/treasury'
import { getEligibilityValues } from './getEligibilityValues'

export const Eligibility = ({
  versionedLoanApplicationId,
  latestApplicationVersion,
  lvr,
  currentEligibilityVerifications,
  // loanFinancials,
}) => {
  const dispatch = useDispatch()

  const warehousesEligibilities = useSelector(selectAllWarehousesEligibilities)

  const warehouseStatuses = warehousesEligibilities.map((warehouse) => {
    const { eligibilityCriteria } = warehouse
    const statuses = eligibilityCriteria.map((criteria) => criteria.status)

    if (statuses.some((status) => status === 'fail')) {
      return 'fail'
    }

    if (
      statuses.some((status) => status === 'incomplete') &&
      !statuses.some((status) => status === 'fail')
    ) {
      return 'incomplete'
    }

    return 'pass'
  })

  const eligibilityPayload = useMemo(() => {
    return getEligibilityValues(
      versionedLoanApplicationId,
      latestApplicationVersion,
      lvr,
      currentEligibilityVerifications
      // loanFinancials
    )
  }, [versionedLoanApplicationId, latestApplicationVersion])

  useEffect(async () => {
    if (!eligibilityPayload) return
    await dispatch(listWarehouseEligibilities(eligibilityPayload))
  }, [dispatch, eligibilityPayload])

  return (
    <div>
      <CircleArrayComponent
        statuses={warehouseStatuses}
        data={warehousesEligibilities}
      />
    </div>
  )
}
