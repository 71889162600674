/* eslint-disable react/no-multi-comp */
import React from 'react'
import { s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'
import { Divider } from '@vega/components/src/v2'
import { VersionedDataDisplay } from 'features/common'
import { DataDisplay } from '../info/components'

const SecurityOwnerSegment = ({ owner, legalEntities }) => {
  const getEntityName = (applicantId) => {
    const listOfEntities = [
      ...(legalEntities?.companies || []),
      ...(legalEntities?.individuals || []),
      ...(legalEntities?.trusts || []),
    ]

    const foundEntity = listOfEntities.find(
      (entity) => entity?.entityId === applicantId
    )

    return foundEntity?.fullName
  }

  const ownerName = getEntityName(owner?.legalEntityId[owner.legalEntityId.length - 1])

  return ownerName ? (
    <VersionedDataDisplay label={`${ownerName}'s Ownership`} values={owner?.percent} />
  ) : (
    <VersionedDataDisplay />
  )
}

export const VersionedSecurityCard = ({
  versionedSecurity,
  estimateValueLabel,
  legalEntities,
}) => {
  const {
    estimatedValue,
    propertyType,
    primaryUsage,
    isSecurity,
    primaryPurpose,
    holding,
    rentalIncome,
    percentOwned,
  } = versionedSecurity || {}

  const largerThanZeroPercentOwned = percentOwned?.owners?.filter(
    (owner) => owner.percent[owner.percent.length - 1].replace(/%/g, '') > 0
  )

  return (
    <Row>
      <Col span={6}>
        <VersionedDataDisplay
          label={estimateValueLabel}
          testId={`${TEST_IDS.MYCRM_ESTIMATE}`}
          values={estimatedValue?.value}
          valueStyle={s('mt-1')}
        />
      </Col>

      <Col span={6}>
        <VersionedDataDisplay
          testId={`${TEST_IDS.PROPERTY_TYPE}`}
          label="Property Type"
          values={propertyType?.propertyTypeName}
          valueStyle={s('mt-1')}
        />
      </Col>

      <Col span={6}>
        <VersionedDataDisplay
          label="Property Usage"
          testId={`${TEST_IDS.PROPERTY_USAGE}`}
          values={primaryUsage}
          valueStyle={s('mt-1')}
        />
      </Col>

      <Col span={6}>
        <DataDisplay
          label="Used as Security"
          testId={`${TEST_IDS.USED_AS_SECURITY}`}
          value={isSecurity ? 'Yes' : 'No'}
          valueStyle={s('mt-1 ml-1')}
          labelStyle={s('ml-1')}
        />
      </Col>

      <Col span={6}>
        <VersionedDataDisplay
          label="Monthly Rental Income"
          testId={`${TEST_IDS.RENTAL_INCOME}`}
          values={rentalIncome ? rentalIncome[0]?.rentalAmount : ['-']}
          valueStyle={s('mt-1')}
        />
      </Col>

      <Col span={6}>
        <VersionedDataDisplay
          label="Primary Purpose"
          testId={`${TEST_IDS.PRIMARY_PURPOSE}`}
          values={primaryPurpose}
          valueStyle={s('mt-1')}
        />
      </Col>

      <Col span={6}>
        <VersionedDataDisplay
          label="Holding"
          testId={`${TEST_IDS.PROPERTY_HOLDING}`}
          values={holding}
          valueStyles={s('mt-1')}
        />
      </Col>
      <Col span={24}>
        <div style={s('mt-3')}>
          <Divider />
        </div>
      </Col>

      <Col span={24}>
        <h4 style={s('mt-1 mb-2')}>Owners</h4>
      </Col>

      {largerThanZeroPercentOwned.map((owner, index) => (
        <Col span={6} key={index}>
          <SecurityOwnerSegment owner={owner} legalEntities={legalEntities} />
        </Col>
      ))}
    </Row>
  )
}
