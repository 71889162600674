import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'
import { treasuryService } from 'apiService'

export const listWarehouseEligibilities = createAsyncThunk(
  'treasury/listWarehouseEligibilities',
  async (eligibilityPayload, { rejectWithValue }) => {
    try {
      return await treasuryService.listWarehouseEligibilities(eligibilityPayload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const listEligibilityVerifications = createAsyncThunk(
  'treasury/listEligibilityVerifications',
  async ({ rejectWithValue }) => {
    try {
      return await treasuryService.listEligibilityVerifications()
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const warehouseEligibilitiesAdapter = createEntityAdapter()

// Entity Adapter for Eligibility Verifications
export const eligibilityVerificationsAdapter = createEntityAdapter({
  selectId: (verification) => verification._id,
})

// Initial State for Warehouse Eligibilities
const warehouseEligibilitiesInitialState = warehouseEligibilitiesAdapter.getInitialState()

// Initial State for Eligibility Verifications
const eligibilityVerificationsInitialState = eligibilityVerificationsAdapter.getInitialState()

const treasurySlice = createSlice({
  name: 'treasury',
  initialState: {
    warehouseEligibilities: warehouseEligibilitiesInitialState,
    eligibilityVerifications: eligibilityVerificationsInitialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listWarehouseEligibilities.fulfilled, (state, { payload }) => {
      warehouseEligibilitiesAdapter.setAll(state.warehouseEligibilities, payload)
    })

    builder.addCase(listEligibilityVerifications.fulfilled, (state, { payload }) => {
      eligibilityVerificationsAdapter.setAll(state.eligibilityVerifications, payload)
    })
  },
})

export const { reducer: treasuryReducer } = treasurySlice
