import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { APPLICATION_INFO_SECTION_IDS } from '../info/constants'
import { SideNavigationItem } from './SideNavigationItem'

const {
  LOAN_INFO,
  APPLICANTS,
  TRUSTS,
  BUSINESSES,
  XAI_CONNECTIONS,
  CRR,
  MYCRM_ADVISER_NOTES,
  HOUSEHOLD_INFO,
  // GUARANTEES,
  SUPPORTING_DOCS,
  BROKER_INFO,
  USER_INFO,
  REFERENCE_DETAILS,
  SUMMARY_NOTES,
  ACTIVITY_LOG,
} = APPLICATION_INFO_SECTION_IDS

const Container = styled.div(
  s('w-full rounded-lg border-1 border-solid border-grey-200 bg-white py-3')
)
const Title = styled.div(
  s('w-full flex text-base font-semibold py-2 pr-3 mb-1 items-center', {
    color: '#141616',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
    paddingLeft: 18,
  })
)
const getSteps = (brokerType) => [
  { name: 'Loan Info', sectionId: LOAN_INFO },
  { name: 'Applicant(s)', sectionId: APPLICANTS },
  { name: 'Trust(s)', sectionId: TRUSTS },
  { name: 'Business(es)', sectionId: BUSINESSES },
  { name: 'Xapii Connections', sectionId: XAI_CONNECTIONS },
  { name: 'Credit Risk Rating', sectionId: CRR },
  { name: 'Household Info', sectionId: HOUSEHOLD_INFO },
  { name: 'MyCRM Adviser Notes', sectionId: MYCRM_ADVISER_NOTES },
  { name: 'Supporting Docs', sectionId: SUPPORTING_DOCS },
  {
    name: brokerType === 'Online Channel' ? 'User Info' : 'Broker Info',
    sectionId: brokerType === 'Online Channel' ? USER_INFO : BROKER_INFO,
  },
  { name: 'Reference Details', sectionId: REFERENCE_DETAILS },
  { name: 'Summary Notes', sectionId: SUMMARY_NOTES },
  { name: 'Activity Log', sectionId: ACTIVITY_LOG },
]

// eslint-disable-next-line react/display-name
const SidebarNavigation = ({
  activeSection,
  setActiveSection,
  brokerType,
  ...props
}) => {
  const steps = getSteps(brokerType)
  return (
    <Container {...props}>
      <Title>Application Info.</Title>

      {steps.map(({ name, sectionId }, index, { length }) => {
        const lastStep = index === length - 1
        return (
          <SideNavigationItem
            key={index}
            isActive={activeSection === sectionId}
            link={`#${sectionId}`}
            name={name}
            onClick={() => {
              const anchor = document.getElementById(sectionId)
              const root = document.getElementById('router-root')

              root.scrollTo({
                top: anchor.offsetTop + 195,
                behavior: 'smooth',
              })
              setActiveSection(sectionId)
            }}
            style={s(lastStep ? 'mb-0' : '')}
          />
        )
      })}
    </Container>
  )
}

export { SidebarNavigation }
