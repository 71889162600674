import React, { useState } from 'react'
import { Formik as FormProvider, Form as FormBase, Field } from 'formik'
import { useDispatch } from 'react-redux'
import { Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { ReactComponent as XIconBase } from '@vega/components/src/assets/images/x.svg'

import neoLogo from '@vega/components/src/assets/images/neo-logo.png'
import { Modal, TextField } from '@vega/components'
import { updateEligibilityVerifications } from 'modules/versionedApplication'

const Container = styled.div(
  s('bg-white h-full p-4', {
    height: '100vh',
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    width: '100vw',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  })
)

const Header = styled(Row)(s('items-center px-16 mb-4'))
const XIcon = styled(XIconBase)({ '&:hover': { cursor: 'pointer' } })
const Img = styled.img(s('w-5 ml-10'))

const Form = styled(FormBase)(s('flex flex-column flex-1'))

const Title = styled.h1(s('font-semibold  mb-4 text-primary'))
const SubTitle = styled.h3(s('font-semibold mb-0'))

const CriteriaWrapper = styled.div(s('p-2 rounded-lg', {
  ':nth-child(even)': s('bg-grey-lighter')
}))

function EligibilityVerificationsModal({
  versionedLoanApplicationId,
  currentEligibilityVerifications = [],
  eligibilityVerifications = [],
  closeModal,
  isOpen,
}) {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')

  const initialValues = {}
  eligibilityVerifications.forEach((item) => {
    const matchingVerification = currentEligibilityVerifications?.find(
      (currentEligibilityVerification) =>
        currentEligibilityVerification?._id === item._id
    )

    initialValues[item._id] = matchingVerification
      ? matchingVerification.value
      : undefined
  })

  const onSubmit = async (values) => {
    const transformedValues = Object.entries(values)
      .map(([key, value]) => ({
        _id: key,
        title: eligibilityVerifications.find((item) => item._id === key)?.title,
        value,
      }))
      .filter(({ value }) => value !== undefined)

    await dispatch(
      updateEligibilityVerifications({ versionedLoanApplicationId, transformedValues })
    )
  }

  const filteredVerifications = (values) => {
    return eligibilityVerifications
      .filter((verification) => verification.title.toUpperCase().includes(search.toUpperCase()))
      .sort((a, b) => {
        const sortValue = (verification) => {
          const matchingInput = values[verification._id]
          return matchingInput ? 1 : 0
        }

        return sortValue(a) - sortValue(b)
      })
  }

  const title = (values) => {
    const filledOut = Object.keys(values)
      .filter((key) => values[key] !== undefined)
      .length

    return `Checklist (${filledOut}/${eligibilityVerifications.length})`
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Container>
        <Header>
          <XIcon onClick={closeModal} />
          <Img src={neoLogo} />
        </Header>
        <FormProvider initialValues={initialValues} onSubmit={onSubmit}>
          {({values, ...props}) => (
            <Form>
              <Row justify="center">
                <Col span={12}>
                  <Title>{title(values)}</Title>

                  <TextField
                    name="Search"
                    placeholder="Search by item name"
                    onChange={(e) => setSearch(e.target.value)}
                    style={s('mb-8')}
                  />

                  {filteredVerifications(values).map((eligibility) => (
                    <CriteriaWrapper key={eligibility._id}>
                      <SubTitle>{eligibility.title}</SubTitle>
                      <div style={s('mb-6 flex')}>
                        <span style={s('w-full')}>{eligibility.description}</span>

                        <Field
                          as="select"
                          name={eligibility._id}
                          onChange={(e) => {
                            props.handleChange(e)
                            props.handleSubmit()
                          }}
                          style={s('rounded-lg py-2 px-4', {
                            width: '100px',
                            height: '50px',
                            marginLeft: '100px',
                          })}
                        >
                          <option />
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="N/A">N/A</option>
                        </Field>
                      </div>
                    </CriteriaWrapper>
                  ))}
                </Col>
              </Row>
            </Form>
          )}
        </FormProvider>
      </Container>
    </Modal>
  )
}

export default EligibilityVerificationsModal
