import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { TEST_IDS } from '@vega/constants'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
  DataDisplay,
} from '../components'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { formatFullName, formatPhoneNumber } from 'utils/formatters'

const { BROKER_INFO, USER_INFO } = APPLICATION_INFO_SECTION_IDS
const Container = styled.div({ marginBottom: 52 })
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const ContentCard = styled(ContentCardBase)(s('mb-4', { overflowX: 'auto' }))
const SubTitleAndValueContainer = styled.div(s('flex flex-column mr-4', { width: 204 }))

const InfoRow = styled.div(s('flex flex-row'))

const BrokerInfo = ({ info = {}, ...props }) => {
  const { firstName, surname: lastName, phone, email, company, domainType } = info

  if (domainType === 'Online Channel') {
    return (
      <Container id={USER_INFO} {...props}>
        <SectionTitle title="User Info" />

        <ContentCard>
          <InfoRow>
            <SubTitleAndValueContainer>
              <DataDisplay
                label="User Name"
                testId={TEST_IDS.BROKER_NAME}
                value={formatFullName({ firstName, lastName })}
              />
            </SubTitleAndValueContainer>
            <SubTitleAndValueContainer>
              <DataDisplay
                label="Source"
                testId={TEST_IDS.BROKER_COMPANY}
                value="Apply-Online"
              />
            </SubTitleAndValueContainer>
            <SubTitleAndValueContainer>
              <DataDisplay
                label="Mobile Phone"
                testId={TEST_IDS.BROKER_NUMBER}
                value={formatPhoneNumber(phone)}
              />
            </SubTitleAndValueContainer>
            <SubTitleAndValueContainer>
              <DataDisplay
                label="User Email"
                testId={TEST_IDS.BROKER_EMAIL}
                value={email}
              />
            </SubTitleAndValueContainer>
          </InfoRow>
        </ContentCard>
      </Container>
    )
  }

  return (
    <Container id={BROKER_INFO} {...props}>
      <SectionTitle title="Broker Info" />

      <ContentCard>
        <InfoRow>
          <SubTitleAndValueContainer>
            <DataDisplay
              label="Broker Name"
              testId={TEST_IDS.BROKER_NAME}
              value={formatFullName({ firstName, lastName })}
            />
          </SubTitleAndValueContainer>
          <SubTitleAndValueContainer>
            <DataDisplay
              label="Broker Company"
              testId={TEST_IDS.BROKER_COMPANY}
              value={company?.name}
            />
          </SubTitleAndValueContainer>
          <SubTitleAndValueContainer>
            <DataDisplay
              label="Mobile Phone"
              testId={TEST_IDS.BROKER_NUMBER}
              value={formatPhoneNumber(phone)}
            />
          </SubTitleAndValueContainer>
          <SubTitleAndValueContainer>
            <DataDisplay
              label="Broker Email"
              testId={TEST_IDS.BROKER_EMAIL}
              value={email}
            />
          </SubTitleAndValueContainer>
        </InfoRow>
      </ContentCard>
    </Container>
  )
}

export { BrokerInfo }
