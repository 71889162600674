import { buildAddress, formatCurrency, formatStringToNumber } from 'utils/formatters'

export const mapToArrears = (individuals = []) => {
  const mappedIndividuals = individuals.map((individual) => {
    const {
      anticipatedChanges,
      mitigants,
      significantChanges,
    } = individual.responsibleLending

    const changes = significantChanges.map((change, index) => {
      const { description, startDate, endDate } = change
      const { description: notes, factor } = mitigants[index]

      return {
        impactEndDate: new Date(endDate),
        impactStartDate: new Date(startDate),
        mitigant: { factor, notes },
        natureOfChange: description,
      }
    })

    return {
      personName: individual.personName,
      responsibleLending: { anticipatedChanges, changes },
    }
  })

  return mappedIndividuals
}

export const financialsByType = (financials, type) => {
  return (
    financials.find(({ financialType }) => financialType === type)?.financials || []
  )
}

// NOTE: as assets/ liabilities are porportionally owened it is tricky to
// differentiate the same owners from both a trust and a household... but
// looks like trusts have 'Equal' ownership as opposed to 'Specified'
export const financialsForEntities = (
  financials,
  entities,
  ownershipType = 'Specified'
) => {
  const entityIds = entities.map((entity) => entity.entityId)

  const financialsOfOwnershipType = financials.filter(
    (financial) => financial.percentOwned.proportions === ownershipType
  )

  // NOTE: currently no way to differentiate which trust financials may
  // belong to, going to assume just one trust in the interest of moving
  // forward while reaching out to myCRM to see how to best sort this in
  // a more robust way
  if (ownershipType === 'Equal') {
    return financialsOfOwnershipType
  }

  return financialsOfOwnershipType.filter((financial) => {
    return financial.percentOwned.owners
      .filter((owner) => owner.percent > 0)
      .map((owner) => owner.legalEntityId)
      .some((ownerId) => entityIds.includes(ownerId))
  })
}
// eslint-disable-next-line complexity
export const calculateMonthlyAmount = (amount, frequency) => {
  if (typeof amount === 'string') {
    amount = formatStringToNumber(amount)
  }
  switch (frequency) {
    case 'Half-yearly':
      return Math.round((amount * 100 * 2) / 12 / 100)
    case 'Quarterly':
      return Math.round((amount * 100 * 4) / 12 / 100)
    case 'Fortnightly':
      return Math.round((amount * 100 * 26) / 12 / 100)
    case 'Monthly':
      return Math.round(amount)
    case 'Weekly':
      return Math.round((amount * 100 * 52) / 12 / 100)
    case 'Yearly':
      return Math.round(amount / 12)
    default:
      return Math.round((amount * 100) / 12 / 100)
  }
}

export const financialsOtherIncomes = (otherIncomes) => {
  const otherIncomesRows = []

  otherIncomes.map((otherIncome) => {
    const amountLastSub = otherIncome?.amount?.length - 1

    if (otherIncome.amount[amountLastSub]) {
      const newAmount = otherIncome.amount.map((amnt) => {
        return formatCurrency(calculateMonthlyAmount(amnt, otherIncome.frequency))
      })
      const monthlyAmount = newAmount[amountLastSub]

      const ownershipLabel = `${otherIncome.percentOwned.owners[0].legalEntity.firstName} ${otherIncome.percentOwned.owners[0].percent}%`
      otherIncomesRows.push({
        type: otherIncome.domainType,
        employers: otherIncome?.description[0] || '-',
        duration: '-',
        occupation: '-',
        ownership: ownershipLabel,
        frequency: 'Monthly',
        amount: otherIncome.amount[amountLastSub],
        monthlyAmount: formatStringToNumber(monthlyAmount),
        formattedAmount: newAmount,
      })
    }

    return []
  })

  return otherIncomesRows
}

export const expensesForEntities = (expenses, entities) => {
  const entityIds = entities.map((entity) => entity.entityId)

  return expenses.filter((financial) => {
    return financial?.percentResponsible?.owners
      .filter((owner) => owner.percent > 0)
      .map((owner) => owner.legalEntity.id)
      .some((ownerId) => entityIds.includes(ownerId))
  })
}

export const mergeIndividualsDetails = (financials, individuals) => {
  return financials.map((financial) => {
    const mergedOwners = financial.percentOwned.owners.map((owner) => {
      const matchingIndividual = individuals.find(
        (individual) => owner.legalEntityId === individual.entityId
      )

      return {
        percent: owner.percent,
        legalEntity: {
          firstName: matchingIndividual?.personName?.firstName,
          lastName: matchingIndividual?.personName?.surname,
          businessName: matchingIndividual?.businessName,
          id: matchingIndividual?.entityId,
        },
      }
    })

    const clonedFinancial = JSON.parse(JSON.stringify(financial))
    clonedFinancial.percentOwned.owners = mergedOwners

    return clonedFinancial
  })
}

export const householdTitle = (householdIndividuals) => {
  const firstNames = householdIndividuals.map(
    (individual) => individual.personName.firstName
  )

  return firstNames > 2
    ? `${firstNames.slice(0, -1).join(', ')} & ${firstNames[firstNames.length - 1]}`
    : firstNames.join(' & ')
}

export const mapToAdverseChanges = (individuals = []) => {
  const mappedIndividuals = individuals.map((individual) => {
    const {
      anticipatedChanges,
      mitigants,
      significantChanges,
    } = individual.responsibleLending

    const changes = significantChanges.map((change, index) => {
      const { description, startDate, endDate } = change
      const { description: notes, factor } = mitigants[index]

      return {
        impactEndDate: new Date(endDate),
        impactStartDate: new Date(startDate),
        mitigant: { factor, notes },
        natureOfChange: description,
      }
    })

    return {
      personName: individual.personName,
      responsibleLending: { anticipatedChanges, changes },
    }
  })

  return mappedIndividuals
}

export const rentalIncomesFromAssets = (realEstateAssets) => {
  return realEstateAssets
    .filter((asset) =>
      asset.rentalIncomes.some(
        (income) =>
          formatStringToNumber(income.rentalAmount[income.rentalAmount.length - 1]) > 0
      )
    )
    .flatMap((asset) => {
      const ownershipLabel = (finance) => {
        const {
          percentOwned: { owners },
        } = finance

        const contributingOwners = owners.filter((owner) => owner.percent > 0)

        if (contributingOwners.length === 1) {
          return `${owners[0].legalEntity.firstName} ${owners[0].percent}%`
        }
        return contributingOwners.map((owner) => `${owner.percent}%`).join(' - ')
      }

      return asset.rentalIncomes.map((rentalIncome) => {
        const lastSub = rentalIncome.rentalAmount.length - 1
        return {
          type: 'Rental income',
          employers: buildAddress(
            {
              streetName: asset.address?.standard?.streetName,
              streetNumber: asset.address?.standard?.streetNumber,
              streetType: asset.address?.standard?.streetType,
            },
            asset.address?.suburb,
            asset.address?.city,
            asset.address?.countryISO,
            asset.address?.standard?.unit,
            asset.address?.newZealandPostCode
          ),
          duration: '-',
          occupation: '-',
          ownership: ownershipLabel(asset),
          frequency: rentalIncome.frequency,
          amount: rentalIncome.rentalAmount[lastSub],
          monthlyAmount: formatStringToNumber(rentalIncome.rentalAmount[lastSub]),
          formattedAmount: rentalIncome.rentalAmount,
        }
      })
    })
}

const incomeRowsForEmployment = (employment, individual, relatedCompanies) => {
  const incomeRows = []

  const { selfEmployed } = employment

  if (selfEmployed.externalRef) {
    const { businessIncomeRecent } = selfEmployed

    const amount = businessIncomeRecent.profitBeforeTax.map(
      (profitBeforeTax, index) => {
        const profitAfterTax = businessIncomeRecent.profitAfterTax[index]

        if (profitBeforeTax === null && profitAfterTax === null) {
          return null
        }

        if (profitBeforeTax !== null) {
          return formatCurrency((formatStringToNumber(profitBeforeTax) / 12) * 0.72)
        }

        return formatCurrency(formatStringToNumber(profitAfterTax) / 12)
      }
    )

    const formattedDuration = `${selfEmployed.duration.length} ${selfEmployed.duration.units}`
    const ownershipLabel = `${individual.personName.firstName} 100%`
    const latestAmount = amount[amount.length - 1] || '$0'

    incomeRows.push({
      type: 'Self employed',
      employers: employment.selfEmployed.business.industry,
      duration: formattedDuration,
      occupation: selfEmployed.occupation,
      ownership: ownershipLabel,
      frequency: 'Monthly',
      amount: latestAmount,
      monthlyAmount: formatStringToNumber(latestAmount),
      formattedAmount: amount,
    })
  }

  const {
    PAYE: { income },
  } = employment

  const incomeTypes = [
    {
      label: 'Salary / wages',
      amount: income.grossSalaryAmount,
      frequency: income.grossSalaryFrequency,
    },
    {
      label: 'Bonus',
      amount: income.bonusAmount,
      frequency: income.bonusFrequency,
    },
    {
      label: 'Commission',
      amount: income.commissionAmount,
      frequency: income.commissionFrequency,
    },
    {
      label: 'Regular overtime',
      amount: income.grossRegularOvertimeAmount,
      frequency: income.grossRegularOvertimeFrequency,
    },
    {
      label: 'Car allowance',
      amount: income.carAllowanceAmount,
      frequency: income.carAllowanceFrequency,
    },
    {
      label: 'Work allowance',
      amount: income.workAllowanceAmount,
      frequency: income.workAllowanceFrequency,
    },
    {
      label: 'Workers compensation',
      amount: income.workersCompensationAmount,
      frequency: income.workersCompensationFrequency,
    },
  ]

  const employer = relatedCompanies
    .flat()
    .find((company) => company.externalRef === employment.PAYE.xEmployer)?.businessName

  const formattedDuration = `${employment.PAYE.duration.length} ${employment.PAYE.duration.units}`

  const ownershipLabel = `${individual.personName.firstName} 100%`

  const {
    PAYE: { occupation },
  } = employment

  incomeTypes.forEach((incomeType) => {
    const amountLastSub = incomeType?.amount?.length - 1

    if (incomeType.amount[amountLastSub]) {
      const newAmount = incomeType.amount.map((amnt) => {
        return formatCurrency(calculateMonthlyAmount(amnt, incomeType.frequency))
      })
      const monthlyAmount = newAmount[amountLastSub]

      incomeRows.push({
        type: incomeType.label,
        employers: employer,
        duration: formattedDuration,
        occupation,
        ownership: ownershipLabel,
        frequency: 'Monthly',
        amount: incomeType.amount[amountLastSub],
        monthlyAmount: formatStringToNumber(monthlyAmount),
        formattedAmount: newAmount,
      })
    }
  })

  return incomeRows
}

export const employmentIncomesForIndividual = (individual, relatedCompanies) => {
  return individual.employments
    .filter(
      (employment) =>
        employment.PAYE.status !== 'Previous' &&
        (employment.selfEmployed?.status !== 'Previous' || !employment.selfEmployed)
    )
    .sort((a, b) => {
      if (a.PAYE.status === 'Primary' && b.PAYE.status === 'Secondary') {
        return -1
      }

      if (a.PAYE.status === 'Secondary' && b.status === 'Primary') {
        return 1
      }

      return 0
    })
    .map((employment) => {
      // console.log('employment', employment);
      // console.log('individual', individual);
      // console.log('relatedCompanies', relatedCompanies);
      return incomeRowsForEmployment(employment, individual, relatedCompanies)
    })
    .flat()
}

// TODO: fill in actual data once heard back from myCRM
export const householdArrearsSummary = (householdTitle) => {
  return {
    householdTitle,
    arrearsSummary: true,
    previousArrears: true,
    currentArrears: false,
    directorLast7Years: false,
    goodAccountSatement: true,
  }
}

// TODO: fill in actual data once heard back from myCRM
export const entityInsuranceDeclaration = (entityName) => {
  return {
    entityName,
    familyLifeAndHardship: false,
    content: true,
  }
}
