import React from 'react'
import { Col, Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import {
  SectionTitle as SectionTitleBase,
  ContentCard as ContentCardBase,
  DataDisplay,
} from '../components'
import { TEST_IDS } from '@vega/constants'

const { REFERENCE_DETAILS } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('px-6'))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

const ReferenceDetails = ({ referenceDetails = {}, ...props }) => {
  const { brokerReference, mycrmReference } = referenceDetails

  return (
    <Container id={REFERENCE_DETAILS} {...props}>
      <SectionTitle title="Reference Details" />

      <ContentCard>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <DataDisplay
              label="MyCRM Reference"
              testId={TEST_IDS.MYCRM_REFERENCE}
              value={mycrmReference}
            />
          </Col>

          <Col span={12}>
            <DataDisplay
              label="Broker Reference"
              testId={TEST_IDS.BROKER_REF}
              value={brokerReference}
            />
          </Col>
        </Row>
      </ContentCard>
    </Container>
  )
}

export { ReferenceDetails }
