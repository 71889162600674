import { useEffect, useState, useCallback } from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { useDispatch } from 'react-redux'
import { useInterval } from 'hooks/useInterval'
import {
  conductAffordabilityAssessment,
  updateAffordabilityAssessment,
  fetchAffordabilityAssessments,
} from 'modules/affordabilityAssessment'
import { AFFORDABILITY_ASSESSMENT_CONSTANTS } from '@vega/constants'
import { useParams } from 'react-router-dom'

const { ASSESSMENT_STATUS } = AFFORDABILITY_ASSESSMENT_CONSTANTS

const XAI_POLLING_INTERVAL_MS = 10000

// TODO: wat naming! Hook within a hook?
function useAutoLinkXai(
  dataIsReady,
  hasRetryRequiredOrIdleAssessments,
  versionedLoanApplicationId,
  individualsConductingAssessment = []
) {
  const dispatch = useDispatch()
  const [hasAttemptedAutoActivateXai, setHasAttemptedAutoActivateXai] = useState(false)
  const [isActivatingXai, setIsActivatingXai] = useState(false)

  const activateXai = useCallback(
    async (versionedLoanApplicationId) => {
      setIsActivatingXai(true)
      await dispatch(conductAffordabilityAssessment(versionedLoanApplicationId))
      await dispatch(fetchAffordabilityAssessments(versionedLoanApplicationId))
      setIsActivatingXai(false)
    },
    [dispatch]
  )

  useEffect(() => {
    function automaticallyActivateXai() {
      if (
        dataIsReady &&
        hasRetryRequiredOrIdleAssessments &&
        !hasAttemptedAutoActivateXai
      ) {
        activateXai(versionedLoanApplicationId)
        setHasAttemptedAutoActivateXai(true)
      }
    }

    automaticallyActivateXai()
  }, [
    activateXai,
    dataIsReady,
    hasAttemptedAutoActivateXai,
    hasRetryRequiredOrIdleAssessments,
    versionedLoanApplicationId,
  ])

  // Pending indicates the assessment process is still in progress, and it will eventually change to either conducted or retry_required
  // Otherwise it is a system unexpected state
  // Keep polling until NO individuals' assessment status is pending
  useInterval(
    () => {
      // dispatch(fetchLoanApplicationEntities(versionedLoanApplicationId))
      dispatch(fetchAffordabilityAssessments(versionedLoanApplicationId))
    },
    individualsConductingAssessment.length > 0,
    XAI_POLLING_INTERVAL_MS
  )

  return { hasAttemptedAutoActivateXai, activateXai, isActivatingXai }
}

// eslint-disable-next-line complexity
const getAssessmentStatusToIndividuals = (individuals, affordabilityAssessments) => {
  // completed - conductedAt is not null
  // pending - if status is pending
  // retryRequired - if status is retry required
  // financialsUpdated - if financials updated at is not null
  // idle - not any of the above

  // TODO: see if a reduce would be better than the loop
  const assessmentProcess = {
    completed: [],
    pending: [],
    retryRequired: [],
    financialsUpdated: [],
    idle: [],
  }

  for (const individual of individuals) {
    const matchingAssessment = affordabilityAssessments
      .find((assessment) => assessment.legalEntityExternalRef === individual.externalRef)

    const assessmentReference = matchingAssessment?.assessmentReference
    const affordabilityAssessmentStatus = matchingAssessment?.status
    const conductedAt = matchingAssessment?.conductedAt
    const financialsUpdatedAt = matchingAssessment?.financialsUpdatedAt

    const clonedIndividual = JSON.parse(JSON.stringify(individual))
    clonedIndividual.affordabilityAssessment = {
      assessmentReference,
      financialsUpdatedAt,
      status: affordabilityAssessmentStatus,
    }

    // TODO: not sure what the switch has over if/returns
    switch (true) {
      case isNotNilOrEmpty(conductedAt):
        assessmentProcess.completed.push(clonedIndividual)
        break

      case affordabilityAssessmentStatus === ASSESSMENT_STATUS.PENDING:
        assessmentProcess.pending.push(clonedIndividual)
        break

      case affordabilityAssessmentStatus === ASSESSMENT_STATUS.RETRY_REQUIRED:
        assessmentProcess.retryRequired.push(clonedIndividual)
        break

      case isNotNilOrEmpty(financialsUpdatedAt):
        assessmentProcess.financialsUpdated.push(clonedIndividual)
        break

      default:
        assessmentProcess.idle.push(clonedIndividual)
    }
  }

  return assessmentProcess
}

const hasApplicationLoaded = (individuals = []) => individuals.length > 0

const checkIsXaiActivated = (individuals, individualsCompletedAssessment) => {
  const hasAllIndividualsCompletedAssessment =
    individuals.length === individualsCompletedAssessment.length

  return hasApplicationLoaded(individuals) && hasAllIndividualsCompletedAssessment
}

export function useXaiLinking(individuals, affordabilityAssessments) {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [isUpdatingXaiApplication, setUpdatingXaiApplication] = useState(false)

  const dataIsReady = hasApplicationLoaded(individuals)

  const {
    completed: individualsCompletedAssessment,
    pending: individualsConductingAssessment,
    retryRequired: individualsRequiredRetryAssessment,
    idle: individualsWithoutAssessment,
  } = getAssessmentStatusToIndividuals(individuals, affordabilityAssessments)

  const hasRetryRequiredOrIdleAssessments =
    individualsRequiredRetryAssessment.length > 0 ||
    individualsWithoutAssessment.length > 0

  const {
    hasAttemptedAutoActivateXai,
    activateXai,
    isActivatingXai: isActivatingAutomatically,
  } = useAutoLinkXai(
    dataIsReady,
    hasRetryRequiredOrIdleAssessments,
    id,
    individualsConductingAssessment
  )

  const isActivatingXai =
    isActivatingAutomatically || individualsConductingAssessment.length > 0

  const isXaiActivated = checkIsXaiActivated(
    individuals,
    individualsCompletedAssessment
  )

  const isReactivationRequired =
    hasAttemptedAutoActivateXai && hasRetryRequiredOrIdleAssessments

  const updateXai = useCallback(
    async (assessmentReference) => {
      setUpdatingXaiApplication(true)
      await dispatch(
        updateAffordabilityAssessment({
          versionedLoanApplicationId: id,
          assessmentReference,
          acitivityLogId: id,
        })
      )
      await dispatch(fetchAffordabilityAssessments(id))
      setUpdatingXaiApplication(false)
    },
    [dispatch, id]
  )

  return {
    activateXai,
    updateXai,
    isActivatingXai,
    isXaiActivated,
    isReactivationRequired,
    isUpdatingXaiApplication,
    assessmentStatusToIndividuals: {
      individualsCompletedAssessment,
      individualsConductingAssessment,
      individualsRequiredRetryAssessment,
      individualsWithoutAssessment,
    },
  }
}
