import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatters'
import { TEST_IDS } from '@vega/constants'
import { Divider as DividerBase } from '../components'
import { VersionedValue } from '../../../common/VersionedValue'

const Container = styled.div()
const ApplicantStatus = styled.div(
  s('inline-block mb-2 rounded-md px-2 mr-2', {
    backgroundColor: '#E4E6E6',
    paddingTop: 2,
    paddingBottom: 2,
  }),
  ({ isPrimaryApplicant }) => isPrimaryApplicant && { backgroundColor: '#F9C2E8' }
)
const AddedTag = styled.div(
  s('inline-block mb-2 rounded-md px-2 mr-2 bg-green-300 text-white', {
    paddingTop: 2,
    paddingBottom: 2,
  })
)
const RemovedTag = styled.div(
  s('inline-block mb-2 rounded-md px-2 mr-2', {
    backgroundColor: 'red',
    paddingTop: 2,
    paddingBottom: 2,
  })
)
const nameStyle = s('mb-3 text-lg font-semibold', {
  color: '#141616',
  letterSpacing: '0.01em',
  lineHeight: 1.5,
})

const Label = styled.div(
  s('text-base font-normal flex items-center mr-2', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
    gap: '0 4px;',
  })
)
const valueStyle = s('font-semibold', {})
const Value = styled.span(s('font-semibold'))

const Divider = styled(DividerBase)(s('my-5'))

const getApplicantStatus = (applicantRole, isPrimaryApplicant) => {
  if (applicantRole === 'Guarantor') {
    return 'GUARANTOR'
  }
  if (isPrimaryApplicant) {
    return 'PRIMARY APPLICANT'
  }
  return 'APPLICANT'
}

const PersonalInfo = ({
  dob = [],
  email = [],
  applicantRole,
  isPrimaryApplicant = false,
  maritalStatus = [],
  fullName = [],
  phone = [],
  residencyStatus = [],
  citizenship = [],
  index,
  versionedState = 'unchanged',
  servicingGuarantee,
  securityGuarantee,
  externalRef,
  ...props
}) => {
  return (
    <Container {...props}>
      <div style={s('flex')}>
        <ApplicantStatus isPrimaryApplicant={isPrimaryApplicant}>
          {getApplicantStatus(applicantRole, isPrimaryApplicant)}
        </ApplicantStatus>

        {versionedState === 'added' && <AddedTag>ADDED</AddedTag>}

        {versionedState === 'removed' && <RemovedTag>REMOVED</RemovedTag>}
      </div>

      <VersionedValue
        data-test-id={`${TEST_IDS.APPLICANT_NAME}`}
        values={fullName}
        valueStyles={nameStyle}
      />

      {applicantRole === 'Guarantor' && (
        <>
          <Label>
            Security Guarantee: <Value>{formatCurrency(securityGuarantee)}</Value>
          </Label>
          {!externalRef.includes('Neo') && (
            <Label>
              Servicing Guarantee: <Value>{formatCurrency(servicingGuarantee)}</Value>
            </Label>
          )}
          <Divider />
        </>
      )}

      <Label>
        DOB:
        <VersionedValue
          data-test-id={`${TEST_IDS.APPLICANT_DOB}`}
          values={dob}
          valueStyles={valueStyle}
        />
      </Label>

      <Label>
        Email:{' '}
        <VersionedValue
          data-test-id={`${TEST_IDS.APPLICANT_EMAIL}`}
          values={email}
          valueStyles={valueStyle}
        />
      </Label>

      <Label>
        Marital Status:{' '}
        <VersionedValue
          data-test-id={`${TEST_IDS.APPLICANT_MARITAL_STATUS}`}
          values={maritalStatus}
          valueStyles={valueStyle}
        />
      </Label>

      <Label>
        Phone:{' '}
        <VersionedValue
          data-test-id={`${TEST_IDS.APPLICANT_PHONE_NO}`}
          values={phone}
          valueStyles={valueStyle}
        />
      </Label>

      <Label>
        Residency Status:{' '}
        <VersionedValue
          data-test-id={`${TEST_IDS.APPLICANT_RESIDENCY_STATUS}`}
          values={residencyStatus}
          valueStyles={valueStyle}
        />
      </Label>

      <Label>
        Citizenship:{' '}
        <VersionedValue
          data-test-id={`${TEST_IDS.APPLICANT_CITIZENSHIP}`}
          values={citizenship}
          valueStyles={valueStyle}
        />
      </Label>
    </Container>
  )
}

export { PersonalInfo }
