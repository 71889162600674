import React, { useState } from 'react'
import { styled, s } from '@vega/styled/v2'
import WarehousesEligibilities from './WarehousesEligibilities'

const Shapes = {
  CIRCLE: 'circle',
  SQUARE: 'square',
}

const Circles = styled.div(
  s('flex items-center justify-center mr-1 rounded-3xl text-grey-700', {
    height: 20,
    width: 20,
    cursor: 'pointer',
  }),
  ({ status }) => {
    if (status === 'pass') {
      return s('bg-secondary')
    }
    if (status === 'fail') {
      return s('bg-red')
    }
    return s('border-solid border-secondary')
  },
  ({ shape }) => {
    if (shape === Shapes.CIRCLE) {
      return s(' rounded-full')
    }
    if (shape === Shapes.SQUARE) {
      return s(' rounded-md')
    }
    return s('border-solid ')
  },
  {
    ':hover': {
      boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
    },
  }
)

const CircleArrayComponent = ({ statuses, data }) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState(null)
  const [activeIndex, setActiveIndex] = useState(null)

  const openModal = (warehouseData, overallStatus, index) => {
    setActiveIndex(index)
    setSelectedWarehouse({ ...warehouseData, overallStatus })
  }

  const closeModal = () => {
    setSelectedWarehouse(null)
    setActiveIndex(null)
  }

  return (
    <div style={{ display: 'flex' }}>
      {statuses.map((status, index) => (
        <Circles
          key={index}
          status={status}
          shape={index === activeIndex ? Shapes.SQUARE : Shapes.CIRCLE}
          onClick={() => openModal(data[index], status, index)}
        />
      ))}
      {selectedWarehouse && (
        <WarehousesEligibilities data={selectedWarehouse} setIsOpenModal={closeModal} />
      )}
    </div>
  )
}

export default CircleArrayComponent
