/* eslint-disable no-unused-expressions */
import { isNilOrEmpty, isNotNilOrEmpty } from '@neo/ramda-extra'

const geographyForSecurity = (security) => {
  if (!security) {
    return ''
  }

  const addressParts = security.address.fullAddress.split(',').map((part) => part.trim())
  const area = addressParts[addressParts.length - 1]

  const metroAreas = [
    'AUCKLAND',
    'CHRISTCHURCH',
    'WELLINGTON',
    'HAMILTON',
    'TAURANGA',
    'DUNEDIN',
    'PALMERSTON NORTH',
    'WHANGAREI',
    'NELSON',
    'NEW PLYMOUTH',
    'HASTINGS',
    'ROTORUA',
    'NAPIER',
    'INVERCARGILL',
    'KAPITI COAST',
    'WHANGANUI',
    'GISBORNE',
  ]

  if (metroAreas.includes(area)) {
    return 'metro'
  }

  if (area === 'AUCKLAND') {
    return 'auckland'
  }

  return 'nonMetro'
}

export const getEligibilityValues = (
  versionedLoanApplicationId,
  latestApplicationVersion,
  lvr,
  currentEligibilityVerifications
  // loanFinancials
) => {
  const {
    totalLoanAmount,
    requestedLoans,
    customSecurityValuations,
    loanPurpose,
  } = latestApplicationVersion

  // mapping Term criteria
  const yearspi = []
  const yearsInterestOnly = []

  requestedLoans?.forEach((loan) => {
    if (loan.term.paymentType === 'Interest Only') {
      yearsInterestOnly.push({
        paymentTypeDuration: loan.term.paymentTypeDuration,
        loanPurpose: loanPurpose.primaryPurpose,
      })
    } else {
      yearspi.push({
        totalTermDuration: loan.term.totalTermDuration,
        loanPurpose: loanPurpose.primaryPurpose,
      })
    }
  })

  // mapping eValuations and fullValuations
  const securities = latestApplicationVersion?.securities?.filter((security) => {
    if (security.status === 'Active' && security.isSecurity === true) {
      return security
    }

    return false
  })

  const eValuations = securities?.reduce(
    (totalAmount, security) =>
      totalAmount +
      ((!isNilOrEmpty(customSecurityValuations)
        ? customSecurityValuations?.find(
            ({ financialId }) => financialId === security?.id
          )?.value
        : security.linkedDetails?.valuation?.estimatedValue ||
          security.finalAssetValue ||
          0) || 0),
    0
  )

  // mapping geography
  const purchasingSecurity = (securities || []).find((security) => security.transaction === 'Purchasing')
  const geography = geographyForSecurity(purchasingSecurity)

  // validate minimum age and number of borrowers
  const numberOfBorrowers = latestApplicationVersion?.legalEntities?.individuals?.filter(
    (individual) => individual.applicantRole === 'Borrower'
  )

  const minimumAge =
    numberOfBorrowers?.filter((borrower) => {
      const age =
        new Date().getFullYear() - new Date(borrower.dateOfBirth).getFullYear()
      return age <= 18
    }).length === 0

  // validate only one company and no more then four directors === true
  const companies = isNotNilOrEmpty(latestApplicationVersion?.legalEntities?.companies)
    ? latestApplicationVersion?.legalEntities?.companies?.length <= 1 &&
      latestApplicationVersion?.legalEntities?.companies[0]?.directors?.length <= 4
    : true

  // validate only one trust
  const trusts = latestApplicationVersion?.legalEntities?.trusts?.length <= 1

  // validate aplicants residency or citizenship
  const residencyStatus = (
    latestApplicationVersion?.legalEntities?.individuals || []
  ).every(
    (individual) =>
      individual.citizenship === 'NZ' ||
      individual.residencyStatus === 'Permanently in New Zealand'
  )

  return {
    id: versionedLoanApplicationId,
    geography,
    totalLoanAmount,
    yearspi,
    yearsInterestOnly,
    lvr,
    eValuations,
    numberOfProperties: securities?.length,
    numberOfBorrowers: numberOfBorrowers?.length,
    minimumAge,
    companies,
    trusts,
    residencyStatus,
    currentEligibilityVerifications,
  }
}
