/* eslint-disable no-unused-expressions */
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Loading } from '@vega/components'
import { Col, Row } from '@vega/styled'
import { s } from '@vega/styled/v2'
import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/versionedApplication'
import {
  selectAllAffordabilityAssessments,
  fetchAffordabilityAssessments,
} from 'modules/affordabilityAssessment'
import { getCreditReport } from 'modules/creditReport'
import {
  listSupportingDocuments,
  selectAllSupportingDocuments,
} from 'modules/supportingDocument'
import {
  mapToAdviserNotes,
  mapToApplicants,
  mapToBusinesses,
  mapToVersionedStatedEntities,
  getPreviousEntities,
} from './mappers'
import { Applicants } from './applicants'
import { InfoOverview } from './loanInfoOverview'
import { AdviserNotes } from './adviserNotes'
import { ReferenceDetails } from './referenceDetails'
import { HouseholdInfo } from './householdInfo'
import { Businesses } from './businesses'
import { BrokerInfo } from './brokerInfo'
import { XaiConnections } from './xaiConnections'
import { Trusts } from './trusts'
import { SupportingDocs } from './supportingDocs'
import { ActivityLog } from './activityLog'
import { CreditRiskRating } from './creditRiskRating'
import { APPLICATION_INFO_SECTION_IDS } from './constants'
import { SidebarNavigation } from '../sidebarNavigation'
import { selectAllNotes, fetchNotes } from 'modules/note'
import { SummaryNotes } from './summaryNotes'
import { formatCurrency } from 'utils/formatters'

// eslint-disable-next-line complexity
export const Info = ({ ...props }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [activeSection, setActiveSection] = useState('loan-info')

  const versionedApplication = useSelector(selectApplicationById(id))
  const allNotes = useSelector(selectAllNotes)

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const supportingDocuments = useSelector(selectAllSupportingDocuments)

  const affordabilityAssessments = useSelector(selectAllAffordabilityAssessments)

  const { latestVersionId } = versionedApplication
  const latestApplicationVersion = !versionedApplication?.versions
    ? {}
    : versionedApplication.versions.find(
        (version) => version.applicationId === latestVersionId
      ).application || {}

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchAffordabilityAssessments(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(
      fetchNotes({
        versionedLoanApplicationId: id,
      })
    )
  }, [dispatch, id])

  useEffect(() => {
    if (latestVersionId && latestApplicationVersion.id) {
      dispatch(
        listSupportingDocuments({
          versionedLoanApplicationId: latestApplicationVersion.id,
          loanApplicationId: latestVersionId,
        })
      )
    }
  }, [dispatch, latestVersionId, latestApplicationVersion])

  const cannotAddEntity = !!(
    latestApplicationVersion?.status?.currentStatus === 'settlement_completed' ||
    latestApplicationVersion?.status?.currentStatus === 'settlement_date_booked'
  )

  const {
    brokerInfo = {},
    detailedComments = [],
    householdInfo,
    legalEntities = [],
    relatedCompanies = [],
  } = latestApplicationVersion

  const { individuals = [] } = legalEntities

  const individualExternalRefs =
    individuals?.map(({ externalRef }) => externalRef) ?? []

  useEffect(() => {
    if (individualExternalRefs.length > 0) {
      individualExternalRefs.forEach((externalRef) =>
        dispatch(
          getCreditReport({
            versionedLoanApplicationId: id,
            externalRef,
          })
        )
      )
    }
    // NOTE: using the individualExternalRefs array as is for a dependency causes
    // many unwanted calls to the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, individualExternalRefs.length, id])

  const loanInfo = {
    totalLoanAmount: latestApplicationVersion.totalLoanAmount,
    requestedLoans: latestApplicationVersion.requestedLoans,
    contributionFunds: latestApplicationVersion.contributionFunds,
    needsAnalysis: latestApplicationVersion.needsAnalysis,
    loanPurpose: latestApplicationVersion.loanPurpose,
    legalEntities: latestApplicationVersion.legalEntities,
  }

  const versionedLoanInfo = {
    versionedTotalLoanAmount: [],
    versionedRequestedLoans: [],
    versionedContributionFunds: [],
    versionedNeedsAnalysis: [],
    versionedLoanPurpose: [],
    versionedLegalEntities: [],
  }

  versionedApplication?.versions?.map((version) => {
    const {
      totalLoanAmount,
      requestedLoans,
      contributionFunds,
      needsAnalysis,
      loanPurpose,
      legalEntities,
    } = version.application

    versionedLoanInfo.versionedTotalLoanAmount = [
      ...versionedLoanInfo.versionedTotalLoanAmount,
      formatCurrency(totalLoanAmount),
    ]
    versionedLoanInfo.versionedRequestedLoans = [
      ...versionedLoanInfo.versionedRequestedLoans,
      requestedLoans,
    ]
    versionedLoanInfo.versionedContributionFunds = [
      ...versionedLoanInfo.versionedContributionFunds,
      contributionFunds,
    ]
    versionedLoanInfo.versionedNeedsAnalysis = [
      ...versionedLoanInfo.versionedNeedsAnalysis,
      needsAnalysis,
    ]
    versionedLoanInfo.versionedLoanPurpose = [
      ...versionedLoanInfo.versionedLoanPurpose,
      loanPurpose,
    ]
    versionedLoanInfo.versionedLegalEntities = [
      ...versionedLoanInfo.versionedLegalEntities,
      legalEntities,
    ]
    return true
  })

  const versionedPreviousApplicants = getPreviousEntities(
    versionedApplication,
    'individuals'
  )
  const previousApplicants = mapToApplicants(versionedPreviousApplicants)

  const versionStatusedIndividuals = mapToVersionedStatedEntities(
    versionedApplication,
    'individuals'
  )

  const versionStatusedCompanies = mapToVersionedStatedEntities(
    versionedApplication,
    'companies'
  )

  const versionStatusedTrusts = mapToVersionedStatedEntities(
    versionedApplication,
    'trusts'
  )

  const applicants = mapToApplicants(versionStatusedIndividuals)

  const adviserNotes = mapToAdviserNotes(detailedComments)

  const businesses = mapToBusinesses(versionStatusedCompanies)

  const trusts = versionStatusedTrusts

  const referenceDetails = {
    brokerReference: latestApplicationVersion?.reference?.brokerReference,
    mycrmReference: latestApplicationVersion?.reference?.externalReference,
  }

  const creditRiskRating = latestApplicationVersion?.creditRiskRating

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    const root = document.getElementById('router-root')
    if (root) {
      const updateCurrentNavigationItemToActive = (
        sectionId,
        topOffset,
        componentHeight
      ) => {
        const exitPoint = topOffset + componentHeight + 195
        const entryPoint = topOffset + 195
        const currentScroll = root.scrollTop
        if (currentScroll >= entryPoint && currentScroll < exitPoint) {
          setActiveSection(sectionId)
        }
      }

      const handler = () => {
        Object.values(APPLICATION_INFO_SECTION_IDS).forEach((sectionId) => {
          const element = document.getElementById(sectionId)

          if (element) {
            updateCurrentNavigationItemToActive(
              sectionId,
              element.offsetTop,
              element.clientHeight,
              'application-info'
            )
          }
        })
      }

      root.addEventListener('scroll', handler)

      return () => {
        root.removeEventListener('scroll', handler)
      }
    }
  }, [id])

  if (isFetchingApplication) {
    return <Loading />
  }

  return (
    <Row justify="center" style={s('h-full px-24', { paddingBottom: '17%' })}>
      <Col span={4} style={s('sticky px-14 ', { top: 148 })}>
        <SidebarNavigation
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          brokerType={brokerInfo.domainType}
        />
      </Col>

      <Col span={20}>
        <div {...props}>
          <InfoOverview loanInfo={loanInfo} versionedLoanInfo={versionedLoanInfo} />

          <Applicants
            applicants={applicants}
            relatedCompanies={relatedCompanies}
            latestVersionId={latestVersionId}
            versionedApplicationId={id}
            cannotAddEntity={cannotAddEntity}
            previousApplicants={previousApplicants}
          />

          <Trusts trusts={trusts} />

          <Businesses businesses={businesses} />

          <XaiConnections
            versionedLoanApplication={versionedApplication}
            affordabilityAssessments={affordabilityAssessments}
            individuals={individuals}
            loanApplicationId={latestVersionId}
          />

          <CreditRiskRating crr={creditRiskRating} />

          <HouseholdInfo applicants={applicants} householdInfo={householdInfo} />

          <AdviserNotes adviserNotes={adviserNotes} />

          <SupportingDocs documents={supportingDocuments} />

          <BrokerInfo info={brokerInfo} />

          <ReferenceDetails referenceDetails={referenceDetails} />

          <SummaryNotes allNotes={allNotes} loanApplicationId={id} />

          <ActivityLog loanApplicationId={id} />
        </div>
      </Col>
    </Row>
  )
}
