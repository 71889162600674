/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchLoanApplication } from 'modules/versionedApplication'

import { styled, s } from '@vega/styled/v2'
import EligibilityVerificationsModal from './EligibilityVerificationsModal'

const CheckList = styled.div(
  s('bg-yellow mr-1  text-grey-700 rounded-3xl', {
    cursor: 'pointer',
    width: 'max-content',
    padding: '0 0.5rem',
    fontSize: '14px',
  }),
  ({ color }) => ({ backgroundColor: color }),
  {
    ':hover': {
      boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
    },
  }
)

export const EligibilityVerifications = ({
  versionedLoanApplicationId,
  eligibilityVerifications,
  currentEligibilityVerifications,
}) => {
  const dispatch = useDispatch()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const checkListColor =
    currentEligibilityVerifications.length === eligibilityVerifications.length
      ? '#00FFCD'
      : 'yellow'

  const openModal = () => {
    setIsOpenModal(true)
  }
  const closeModal = () => {
    dispatch(fetchLoanApplication(versionedLoanApplicationId))
    setIsOpenModal(false)
  }

  return (
    <div>
      <CheckList onClick={openModal} color={checkListColor}>
        {`${currentEligibilityVerifications.length}/${eligibilityVerifications.length}`}
      </CheckList>

      <EligibilityVerificationsModal
        versionedLoanApplicationId={versionedLoanApplicationId}
        currentEligibilityVerifications={currentEligibilityVerifications}
        eligibilityVerifications={eligibilityVerifications}
        closeModal={closeModal}
        isOpen={isOpenModal}
      />
    </div>
  )
}
