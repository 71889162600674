import {
  warehouseEligibilitiesAdapter,
  eligibilityVerificationsAdapter,
} from './module'

export const {
  selectAll: selectAllWarehousesEligibilities,
  selectById: selectWarehouseEligibilityById,
} = warehouseEligibilitiesAdapter.getSelectors(
  (state) => state.treasury.warehouseEligibilities
)

export const {
  selectAll: selectAllEligibilityVerifications,
  selectById: selectEligibilityVerificationById,
} = eligibilityVerificationsAdapter.getSelectors(
  (state) => state.treasury.eligibilityVerifications
)
