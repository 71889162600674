/* eslint-disable complexity */
import React, { useRef, useState } from 'react'
import { s, styled } from '@vega/styled/v2'
import { Eligibilities } from './Eligibilities'
import { useOnClickOutside } from '@vega/components'

const PopUpContainer = styled.div(
  s('absolute pin-t-10 rounded-lg z-2 mt-2', {
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.3), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
    width: 600,
    right: 0,
  })
)

const ContentContainer = styled.div(
  s('bg-white rounded-b-lg w-full', {
    height: 392,
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  })
)

const Header = styled.div(
  s('flex items-center px-5 py-6 rounded-t-lg', { maxHeight: 72 }),
  ({ overallStatus }) => {
    if (overallStatus === 'pass') {
      return s('bg-secondary')
    }
    if (overallStatus === 'fail') {
      return s('bg-red')
    }
    return s('bg-green-100')
  }
)

const Title = styled.h3(
  s('m-0 text-primary text-l', { letterSpacing: '-0.01em', lineHeight: 1.2, flexGrow: 1 })
)

const NewNotificationTag = styled.button(
  s('bg-accent-pink ml-1 px-3 rounded-md text-grey-900 text-xs border-0', {
    paddingBottom: 6,
    paddingTop: 6,
    cursor: 'pointer',
  }),
  ({ selected }) => {
    if (selected) {
      return s('font-semibold')
    }

    return null
  }
)
const WarehousesEligibilities = ({ data, setIsOpenModal }) => {
  const warehouseRef = useRef()
  const [filter, setFilter] = useState('all')
  const { eligibilityCriteria } = data

  useOnClickOutside(warehouseRef, () => setIsOpenModal(false), [
    document.getElementById('warehouse-id'),
  ])

  const filteredCriteria = () => {
    if (filter === 'fail') {
      return eligibilityCriteria.filter((criterion) => criterion.status === 'fail')
    }

    if (filter === 'incomplete') {
      return eligibilityCriteria.filter((criterion) => criterion.status === 'incomplete')
    }

    return eligibilityCriteria
  }

  const statusCounts = {}

  for (const crit of data.eligibilityCriteria) {
    if (crit.status in statusCounts) {
      statusCounts[crit.status] += 1
    } else {
      statusCounts[crit.status] = 1
    }
  }

  return (
    <PopUpContainer ref={warehouseRef} id={'warehouse-id'}>
      <Header overallStatus={data.overallStatus}>
        <Title>{data.name}</Title>
        <div>
          {statusCounts.fail && (
            <NewNotificationTag
              selected={filter === 'fail'}
              onClick={() => setFilter('fail')}
            >
              {statusCounts.fail} Failing
            </NewNotificationTag>
          )}
          {statusCounts.incomplete && (
            <NewNotificationTag
              selected={filter === 'incomplete'}
              onClick={() => setFilter('incomplete')}
            >
              {statusCounts.incomplete} Incomplete
            </NewNotificationTag>
          )}
          {data.eligibilityCriteria.length > 0 && (
            <NewNotificationTag
              selected={filter === 'all'}
              onClick={() => setFilter('all')}
            >
              {data.eligibilityCriteria.length} All
            </NewNotificationTag>
          )}
        </div>
      </Header>

      <ContentContainer>
        <Eligibilities eligibilities={filteredCriteria()} />
      </ContentContainer>
    </PopUpContainer>
  )
}

export default WarehousesEligibilities
