/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import { isNotNilOrEmpty, propEq, isNilOrEmpty } from '@solta/ramda-extra'
import { s, styled } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { LEGAL_ENTITY } from '@vega/constants'
import { FormTextarea } from '@vega/components'
import {
  formatFullName,
  formatCurrency,
  formatDateString,
  formatPhoneNumber,
} from 'utils/formatters'
import { calculateTotalInterestRate } from 'utils/calculation'
import { SectionTitle as SectionTitleBase } from '../info/components'
import { LoanSplitDisplay } from './LoanSplitDisplay'
import { LoanAccountItem } from './LoanAccountItem'
import { selectAllSecurities } from 'modules/security'
import { useSelector } from 'react-redux'

const Container = styled.div(
  s('px-5 py-6 rounded-lg w-full', { backgroundColor: '#F7F7F2' })
)
const Label = styled.p(
  s('font-normal m-0 text-base text-grey-700', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const SectionTitle = styled(SectionTitleBase)(s('mb-4 mt-10'))
const Value = styled.p(
  s('font-normal m-0 text-base text-grey-900', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const textAreaLabelStyle = s('mb-1 text-grey-800 text-sm', {
  letterSpacing: '0.01em',
  lineHeight: 1.5,
})

const EntityItem = ({ name, address, email, phone, ...otherProps }) => {
  return (
    <div {...otherProps}>
      <Value style={s('font-bold')}>{name || '-'}</Value>
      <Value>{formatPhoneNumber(phone) || '-'}</Value>
      <Value>{address || '-'}</Value>
      <Value>{email || '-'}</Value>
    </div>
  )
}

const BrokerItem = ({ name, companyName, ...otherProps }) => {
  return (
    <div {...otherProps}>
      <Value>
        {name || '-'} @ {companyName || '-'}
      </Value>
    </div>
  )
}

const SolicitorItem = ({ contactPersonName, companyName, email, ...otherProps }) => {
  return (
    <div {...otherProps}>
      <Value>{contactPersonName || '-'}</Value>
      <Value>{companyName || '-'}</Value>
      <Value>{email || '-'}</Value>
    </div>
  )
}

const groupEntitiesByApplicantRole = (individuals = []) => {
  const borrowers = []
  const guarantors = []

  individuals.forEach((individual) => {
    if (individual.applicantRole === 'Borrower') borrowers.push(individual)
    else if (individual.applicantRole === 'Guarantor') guarantors.push(individual)
  })

  return {
    borrowers,
    guarantors,
  }
}

const mapProduct = (product = {}) => {
  const { fields } = product

  return {
    id: product?.id,
    name: product.name,
    initialRepaymentType: fields?.initialRepaymentType,
    initialRateType: fields?.initialRateType,
    ongoingRepaymentType: fields?.ongoingRepaymentType,
    ongoingRateType: fields?.ongoingRateType,
  }
}

const getAddressByEntityType = (borrower, legalEntityType) => {
  switch (legalEntityType) {
    case LEGAL_ENTITY.TYPE.COMPANY:
      return borrower?.contact?.address?.fullAddress
    case LEGAL_ENTITY.TYPE.TRUST:
      return borrower?.contact?.address?.fullAddress
    default:
      return borrower?.contact?.currentAddress?.fullAddress
  }
}

const getUniqueBorrowersInSplits = (loanSplits = []) => {
  const uniqueBorrowersInSplits = []

  loanSplits.forEach((split) => {
    // eslint-disable-next-line no-unused-expressions
    split?.associatedBorrowers.forEach((borrower) => {
      const isDuplicatedBorrower = uniqueBorrowersInSplits.find(
        propEq('id', borrower?.id)
      )

      if (!isDuplicatedBorrower) uniqueBorrowersInSplits.push(borrower)
    })
  })

  return uniqueBorrowersInSplits
}

const getUniqueGuarantorsInSplits = (loanSplits = []) => {
  const uniqueGuarantorsInSplits = []

  loanSplits.forEach((split) => {
    // eslint-disable-next-line no-unused-expressions
    split?.guarantors.forEach((guarantor) => {
      const isDuplicatedBorrower = uniqueGuarantorsInSplits.find(
        propEq('id', guarantor?.id)
      )

      if (!isDuplicatedBorrower) uniqueGuarantorsInSplits.push(guarantor)
    })
  })

  return uniqueGuarantorsInSplits
}

const mapEntities = (allBorrowers = [], associatedBorrowersInSplits = []) => {
  return associatedBorrowersInSplits.map((borrower) => {
    const borrowerDetails = allBorrowers.find(propEq('entityId', borrower?.id)) || {}
    const { contact, legalEntityType, fullName } = borrowerDetails

    return {
      id: borrower.entityId,
      name: fullName,
      address: getAddressByEntityType(borrowerDetails, legalEntityType),
      email: contact?.email,
      phone: contact?.phone,
    }
  })
}

const mapSecurityProperties = (securities = []) => {
  return securities.map((security) => {
    const { id, address = {} } = security

    return {
      id,
      address: address?.fullAddress || '-',
      value: security?.finalAssetValue,
    }
  })
}

const mapSolicitors = (allSolicitors = []) => {
  return allSolicitors.map((solicitor) => {
    const { companyName, contact } = solicitor
    const { email, contactPerson } = contact || {}

    return {
      id: solicitor.id,
      contactPerson: {
        name: formatFullName({
          firstName: contactPerson?.firstName,
          lastName: contactPerson?.surname,
        }),
      },
      companyName,
      email,
    }
  })
}

const mapBrokerInfo = (brokerInfo = {}) => {
  return {
    name: formatFullName({
      firstName: brokerInfo?.firstName,
      lastName: brokerInfo?.surname,
    }),
    companyName: brokerInfo?.company?.name,
  }
}

const mapLoanSplits = (loanSplits = [], allProducts = []) => {
  return loanSplits.map((loanSplit) => {
    const product = allProducts.find((product) => product.id === loanSplit.productId)

    return {
      product: mapProduct(product),
      loanTermInYears: loanSplit?.loanTermInYears,
      loanAmount: loanSplit?.loanAmount,
      repaymentFrequency: loanSplit?.repaymentFrequency,
      loading: loanSplit?.loading,
      baseInterestRate: product?.fields?.ongoingRate,
      securities: loanSplit?.securities,
      loanPurpose: loanSplit?.loanPurpose,
      occupancyType: loanSplit?.occupancyType,
      interestRate: calculateTotalInterestRate(
        product?.fields?.ongoingRate,
        loanSplit?.loading
      ),
    }
  })
}

export const LoanPreview = ({ application, loanSplits = [], products = [] }) => {
  const {
    brokerInfo,
    legalEntities = {},
    solicitors = [],
    customSecurityValuations = [],
    postSettlement = {},
  } = application

  const { individuals = [], trusts = [], companies = [] } = legalEntities
  const losSecurities = useSelector(selectAllSecurities) || []

  const {
    borrowers: allBorrowers,
    guarantors: allGuarantors,
  } = groupEntitiesByApplicantRole([...individuals, ...trusts, ...companies])

  const associatedBorrowersInSplits = getUniqueBorrowersInSplits(loanSplits)
  const guarantorsInSplits = getUniqueGuarantorsInSplits(loanSplits)

  const mappedLoanSplits = mapLoanSplits(loanSplits, products)
  const mappedAllBorrowers = mapEntities(allBorrowers, associatedBorrowersInSplits)
  const mappedAllGuarantors = mapEntities(allGuarantors, guarantorsInSplits)
  const mappedAllSolicitors = mapSolicitors(solicitors)

  const mappedBrokerInfo = mapBrokerInfo(brokerInfo)

  const securities =
    losSecurities
      .filter((security) => security.status === 'Active')
      .map((security) => {
        const customSecurityValuation = customSecurityValuations?.find(
          (item) => item.financialId === security.id
        )
        const customEstimatedValue = customSecurityValuation?.value
        const ivalEstimatedValue = security.linkedDetails?.valuation?.estimatedValue
        const myCrmEstimatedValue = security.estimatedValue?.value

        return {
          ...security,
          finalAssetValue:
            customEstimatedValue ||
            ivalEstimatedValue ||
            myCrmEstimatedValue ||
            undefined,
        }
      }) || []

  const mappedAllSecurityProperties = mapSecurityProperties(
    securities,
    customSecurityValuations
  )

  return (
    <Container>
      <SectionTitle style={s('mt-0')} title="Loan Structure & Details" />

      <Row style={s('mb-4')}>
        {isNilOrEmpty(loanSplits) && (
          <Col span={24}>
            <Label>
              * Please note that the Borrowers&apos; information will not appear if the
              Loan Structure section is not complete
            </Label>
          </Col>
        )}
        <Col span={10}>
          <Label>Borrower(s)</Label>
        </Col>
        <Col span={14}>
          {mappedAllBorrowers?.map((borrower) => (
            <EntityItem
              key={borrower.id}
              name={borrower?.name}
              address={borrower?.address}
              email={borrower?.email}
              phone={borrower?.phone}
              style={s('mb-4')}
            />
          ))}
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Guarantor(s)</Label>
        </Col>
        <Col span={14}>
          {isNotNilOrEmpty(mappedAllGuarantors)
            ? mappedAllGuarantors?.map((guarantor) => (
                <EntityItem
                  key={guarantor.id}
                  name={guarantor?.name}
                  address={guarantor?.address}
                  email={guarantor?.email}
                  phone={guarantor?.phone}
                  style={s('mb-4')}
                />
              ))
            : '-'}
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Total Loan Amount</Label>
        </Col>
        <Col span={14}>
          <Value>{formatCurrency(application?.totalLoanAmount)}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Drawdown Date</Label>
        </Col>
        <Col span={14}>
          <Value>{formatDateString(postSettlement?.postSettlementDate)}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Borrower&apos;s Solicitor</Label>
        </Col>
        <Col span={14}>
          {isNotNilOrEmpty(mappedAllSolicitors)
            ? mappedAllSolicitors?.map((solicitor) => (
                <SolicitorItem
                  key={solicitor.id}
                  contactPersonName={solicitor?.contactPerson?.name}
                  companyName={solicitor?.companyName}
                  email={solicitor?.email}
                  style={s('mb-4')}
                />
              ))
            : '-'}
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Broker&apos;s Name and Firm</Label>
        </Col>
        <Col span={14}>
          <BrokerItem
            name={mappedBrokerInfo?.name}
            companyName={mappedBrokerInfo?.companyName}
          />
        </Col>
      </Row>

      <SectionTitle title="Loan Accounts" />

      {mappedLoanSplits.map((loanSplit) => (
        <LoanAccountItem key={loanSplit.id} loanSplit={loanSplit} style={s('mb-8')} />
      ))}

      {mappedLoanSplits.map((loanSplit, idx) => (
        <LoanSplitDisplay
          key={loanSplit.id}
          loanSplit={loanSplit}
          loanSplitNumber={idx + 1}
        />
      ))}

      <SectionTitle title="Security Property" />

      {mappedAllSecurityProperties.map((security) => (
        <div style={s('mb-6')} key={security?.id}>
          <Row style={s('mb-4')}>
            <Col span={10}>
              <Label style={s('font-bold')}>Property Address</Label>
            </Col>
            <Col span={14}>
              <Value style={s('font-bold')}>{security?.address}</Value>
            </Col>
          </Row>

          <Row style={s('mb-4')}>
            <Col span={10}>
              <Label>Property Value</Label>
            </Col>
            <Col span={14}>
              <Value>{formatCurrency(security?.value, 2)}</Value>
            </Col>
          </Row>

          <Row style={s('mb-4')}>
            <Col span={10}>
              <Label>Insurance</Label>
            </Col>
            <Col span={14}>
              <Value>N/A</Value>
            </Col>
          </Row>
        </div>
      ))}

      <SectionTitle title="Net Loan Amount and Fees to be Charged on Settlement" />

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Total Loan Amount</Label>
        </Col>
        <Col span={14}>
          <Value>{formatCurrency(application?.totalLoanAmount)}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label style={s('font-semibold')}>
            Less fees to be charged on settlement
          </Label>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Document and Settlement Fee</Label>
        </Col>
        <Col span={14}>
          <Value>N/A</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Net Loan Advance to Borrower&apos;s Solicitor</Label>
        </Col>
        <Col span={14}>
          <Value>N/A</Value>
        </Col>
      </Row>

      <SectionTitle title="Disbursement and Special Conditions" />

      <FormTextarea
        containerStyle={s('mb-4')}
        label="Conditions Precedent"
        labelStyle={textAreaLabelStyle}
        name="conditionsPrecedent"
        placeholder="Leave a note"
        rows={4}
        textareaStyle={s('border-grey-400 p-3 rounded-lg')}
      />

      <FormTextarea
        containerStyle={s('mb-4')}
        label="Special Conditions"
        labelStyle={textAreaLabelStyle}
        name="specialConditions"
        placeholder="Leave a note"
        rows={4}
        textareaStyle={s('border-grey-400 p-3 rounded-lg')}
      />

      <FormTextarea
        label="Net Loan Advance to Borrower's Solicitor"
        labelStyle={textAreaLabelStyle}
        name="netLoanAdvance"
        placeholder="Leave a note"
        rows={4}
        textareaStyle={s('border-grey-400 p-3 rounded-lg')}
      />
    </Container>
  )
}
