import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'

import {
  lendingEntityService,
  versionedLoanApplicationService,
  legalEntityService,
} from 'apiService'
import { thunkErrorProcessor, createCustomError } from '@vega/error-standardizer'
import { mapLoanApplication, mapLegalEntity } from './loanApplicationMappers'
import { isNilOrEmpty } from '@solta/ramda-extra'

export const fetchLoanApplication = createAsyncThunk(
  'versionedApplications/getApplication',
  async (id, { rejectWithValue }) => {
    try {
      const loanApplication = await versionedLoanApplicationService.getApplication(id)

      return mapLoanApplication(loanApplication)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateLoanApplication = createAsyncThunk(
  'versionedApplication/updateLoanApplication',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await versionedLoanApplicationService.updateLoanApplication(id, payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

/**
 * @type {import('./types').FetchApplicationsThunk}
 */
export const fetchLoanApplications = createAsyncThunk(
  'versionedApplications/getLoanApplications',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } = searchParams
      return await versionedLoanApplicationService.getLoanApplications(
        {
          q,
          filters,
          limit,
          sorting,
          start: limit * pageIndex,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchLoanApplicationEntities = createAsyncThunk(
  'versionedApplications/fetchLoanApplicationEntities',
  async (loanApplicationId, { rejectWithValue }) => {
    try {
      const legalEntities = await lendingEntityService.listLegalEntities(
        loanApplicationId
      )

      return {
        loanApplicationId,
        legalEntities: {
          individuals: legalEntities
            .filter((e) => e.legalEntityType === 'individual')
            .map(mapLegalEntity),
          trusts: legalEntities
            .filter((e) => e.legalEntityType === 'trust')
            .map(mapLegalEntity),
          companies: legalEntities
            .filter((e) => e.legalEntityType === 'company')
            .map(mapLegalEntity),
        },
      }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const createLegalEntity = createAsyncThunk(
  'versionedApplication/createLegalEntity',
  async ({ newLegalEntity, versionedApplicationId }, { rejectWithValue }) => {
    try {
      if (isNilOrEmpty(newLegalEntity))
        throw createCustomError({
          type: 'legalEntityCreateFailed',
          description: 'Legal Entity Create Failed',
        })

      return await legalEntityService.createLegalEntity({
        newLegalEntity,
        versionedApplicationId,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateLegalEntity = createAsyncThunk(
  'versionedApplication/updateLegalEntity',
  async (
    { entityId, newGuarantor, versionedLoanApplicationId },
    { rejectWithValue }
  ) => {
    try {
      return await legalEntityService.updateLegalEntity(
        entityId,
        newGuarantor,
        versionedLoanApplicationId
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateLoanApplicationStatus = createAsyncThunk(
  'versionedApplication/updateLoanApplicationStatus',
  async ({ loanApplicationId, status }, { rejectWithValue }) => {
    try {
      const updatedApplication = await versionedLoanApplicationService.updateStatus(
        loanApplicationId,
        status
      )

      return mapLoanApplication(updatedApplication)
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const updateEligibilityVerifications = createAsyncThunk(
  'versionedApplication/updateEligibilityVerifications',
  async ({ versionedLoanApplicationId, transformedValues }, { rejectWithValue }) => {
    try {
      const updatedVersion = await versionedLoanApplicationService.updateEligibilityVerifications(
        versionedLoanApplicationId,
        transformedValues
      )

      return updatedVersion
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

/**
 * @type {import('./types').ApplicationEntityAdapter}
 */
export const versionedApplicationAdapter = createEntityAdapter()

/**
 * @type {import('./types').ApplicationEntityState}
 */
const initialState = versionedApplicationAdapter.getInitialState()

const applicationSlice = createSlice({
  name: 'versionedApplication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanApplication.fulfilled, (state, action) => {
        versionedApplicationAdapter.upsertOne(state, action.payload)
      })
      .addCase(fetchLoanApplicationEntities.fulfilled, (state, action) => {
        const { loanApplicationId, legalEntities = {} } = action.payload || {}

        versionedApplicationAdapter.updateOne(state, {
          id: loanApplicationId,
          changes: {
            legalEntities,
          },
        })
      })
      .addCase(fetchLoanApplications.fulfilled, (state, action) => {
        const { items: loanApplications, pagination } = action.payload
        versionedApplicationAdapter.setAll(state, loanApplications)
        state.total = pagination.total
      })
      .addCase(updateLoanApplicationStatus.fulfilled, (state, action) => {
        versionedApplicationAdapter.upsertOne(state, action.payload)
      })
      .addCase(updateEligibilityVerifications.fulfilled, (state, action) => {
        versionedApplicationAdapter.upsertOne(state, action.payload)
      })
  },
})

const { reducer: applicationReducer } = applicationSlice

export { applicationReducer }
